import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO, PatientVO } from 'src/app/services/device-ordering/value-objects/order.vo';

@Component({
  selector: 'app-new-device-modal',
  templateUrl: './new-device-modal.component.html',
  styleUrls: ['./new-device-modal.component.scss'],
})
export class NewDeviceModalComponent implements OnInit {
  addressForm: FormGroup;
  termsForm: FormGroup;
  showForm: boolean = true;

  hasUnitNumber = false;

  public selectedManufacturer = '';
  public selectedDevice: DeviceVO;

  states = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatientVO,
    private dialogRef: MatDialogRef<NewDeviceModalComponent>,
    public deviceService: DeviceOrderingService,
    private dataService: DataService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.states = this.dataService.statesWithabbreviation;
    this.addressForm = this.fb.group({
      firstName: [this.data && this.data.firstName ? this.data.firstName : null, Validators.required],
      lastName: [this.data && this.data.lastName ? this.data.lastName : null, Validators.required],
      address: [this.data && this.data.address && this.data.address.street ? this.data.address.street : null, Validators.required],
      address2: null,
      city: [this.data && this.data.address && this.data.address.city ? this.data.address.city : null, Validators.required],
      state: [this.data && this.data.address && this.data.address.state ? this.data.address.state : null, Validators.required],
      postalCode: [
        this.data && this.data.address && this.data.address.postalCode ? this.data.address.postalCode : null,
        Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(5)]),
      ],
      manufacturer: [''],
      type: [''],
    });
    this.termsForm = this.fb.group({
      terms: ['', Validators.required],
    });

    this.deviceService.getAllDevices().pipe(take(1)).subscribe();
  }

  handleManufacturerSelected() {
    this.deviceService.getDevicesByManufacturer(this.selectedManufacturer).pipe(take(1)).subscribe();
  }

  orderDevice() {
    const device = this.addressForm.getRawValue().type;
    this.dialogRef.close(device);
  }

  onSubmit() {
    this.showForm = false;
  }

  acceptTerms(): void {
    this.dialogRef.close(this.addressForm.value);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
