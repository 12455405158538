<form [formGroup]="emailForm" (ngSubmit)="sendEmail()" class="email-form">
  <h2>Send email</h2>
  <mat-slide-toggle class="email-form-isSecure" (change)="toggleSendSecure()" formControlName="isEmailSecure">
    Send secure
  </mat-slide-toggle>
  <mat-form-field *ngIf="isEmailSecure" appearance="outline">
    <mat-label>From</mat-label>
    <input matInput formControlName="from" />
    <mat-error *ngIf="emailForm.get('from').errors?.emailWelby">Invalid email. Please use a welby account</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>To</mat-label>
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let recipient of emailForm.get('recipients').value"
        [selectable]="selectable"
        [removable]="removable"
        (removed)="remove(recipient)"
        selected
      >
        {{ recipient }}
        <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
      </mat-chip>
      <input
        placeholder="Add recipients"
        [matChipInputFor]="chipList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="addOnBlur"
        (matChipInputTokenEnd)="add($event)"
      />
    </mat-chip-list>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Subject</mat-label>
    <input matInput formControlName="subject" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Message</mat-label>
    <textarea matInput formControlName="message" rows="5"></textarea>
    <mat-hint>Add message</mat-hint>
  </mat-form-field>
  <mat-slide-toggle *ngIf="isEmailSecure" (change)="toggleforceSecureNotification()" formControlName="forceSecureNotification">
    Send Via Portal
  </mat-slide-toggle>
  <div class="email-form-attachments" *ngIf="attachments && attachments.length > 0 && isEmailSecure">
    <h3>Attachments</h3>
    <mat-list class="email-form-attachments-list">
      <mat-list-item *ngFor="let file of attachments">
        <mat-icon mat-list-icon>description</mat-icon>
        <div mat-line>{{ file.fileName }}</div>
        <div mat-line>Type: {{ file.contentType }}</div>
      </mat-list-item>
    </mat-list>
  </div>
  <div class="email-form-buttons">
    <button mat-raised-button color="default" type="button" (click)="close()">Cancel</button>
    <button mat-button *ngIf="isEmailSecure" color="accent" (click)="openSelectFileDialog($event)">
      <mat-icon>file_present</mat-icon> Upload Files
    </button>
    <button mat-raised-button color="primary" [disabled]="emailForm.invalid" type="submit"><mat-icon>email</mat-icon> Send</button>
    <input #inputFile multiple type="file" style="display: none" (change)="onDocumentFileSelected($event)" />
  </div>
  <mat-progress-bar *ngIf="loading" mode="indeterminate"> </mat-progress-bar>
</form>
