<main>
  <div class="container" id="banner" fxLayoutWrap *ngIf="!(auth.user$| async)">

      <mat-card>
        <div *ngIf="isLogin">
          <h3>Sign In</h3>
          <button size="small" mat-stroked-button (click)="changeType('signup')">
            New user?
          </button>
        </div>


        <div *ngIf="isSignup">
          <h3>Create Account</h3>
          <button mat-stroked-button (click)="changeType('login')">
            Returning user?
          </button>
        </div>

        <div *ngIf="isPasswordReset">
          <h3>Reset Password</h3>
          <button size="small" mat-button (click)="changeType('login')">Back</button>
        </div>

        <form [formGroup]="form" (ngSubmit)="onSubmit()">

          <br>
          <div *ngIf="isSignup">
            <mat-slide-toggle [formControl]="toggle">I am a provider</mat-slide-toggle>
          </div>

          <mat-form-field [color]="email.valid && 'accent'">
            <input
              matInput
              formControlName="email"
              type="email"
              placeholder="Email"
              autocomplete="off"
            />

            <mat-error *ngIf="email.invalid && email.dirty">
              You must enter a valid email address
            </mat-error>
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="isSignup" style="width: 48%; margin-right: 2%;">
            <input
              matInput
              formControlName="first_name"
              type="text"
              placeholder="First Name"
              autocomplete="off"
            />
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="isSignup" style="width: 50%;">
            <input
              matInput
              formControlName="last_name"
              type="text"
              placeholder="Last Name"
              autocomplete="off"
            />
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="isSignup && isProvider" style="width: 48%; margin-right: 2%;">
            <input
              matInput
              formControlName="practice_name"
              type="text"
              placeholder="Practice Name"
              autocomplete="off"
            />

            <mat-error *ngIf="practiceName.invalid && practiceName.dirty">
              You must enter a valid practice name
            </mat-error>
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="isSignup && isProvider" style="width: 50%;">
            <input
              matInput
              formControlName="practice_npi"
              type="text"
              placeholder="Practice NPI Number"
              autocomplete="off"
            />

            <mat-error *ngIf="practiceNpi.invalid && practiceNpi.dirty">
              You must enter a valid npi number
            </mat-error>
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="isSignup && !isProvider" style="width: 100%;">
            <input
              matInput
              formControlName="client_id"
              type="text"
              placeholder="Client ID"
              autocomplete="off"
            />

            <!-- <mat-error *ngIf="practiceName.invalid && practiceName.dirty">
              You must enter a valid practice name
            </mat-error> -->
          </mat-form-field>

          <mat-form-field [color]="email.valid && 'accent'" *ngIf="!isPasswordReset">
            <input
              matInput
              formControlName="password"
              type="password"
              placeholder="Password"
              autocomplete="off"
            />

            <mat-error *ngIf="password.invalid && password.dirty">
              Password must be at least 6 characters long
            </mat-error>
          </mat-form-field>

          <mat-form-field
            [color]="passwordDoesMatch ? 'accent' : 'warn'"
            *ngIf="isSignup"
          >
            <input
              matInput
              formControlName="passwordConfirm"
              type="password"
              placeholder="Confirm password"
              autocomplete="off"
            />

            <mat-error *ngIf="passwordConfirm.dirty && !passwordDoesMatch">
              Password does not match
            </mat-error>
          </mat-form-field>

          <section class="checkbox-section" *ngIf="isSignup">
            <mat-checkbox formControlName="terms">By signing up, I agree to be bound to <strong><a routerLink="/terms">terms of service</a></strong> of Welby Health</mat-checkbox>
            <br>
            <mat-checkbox *ngIf="!isProvider" formControlName="consent">I agree to consent to <a routerLink="/terms">billing requirements</a> of Welby Health</mat-checkbox>
            <br *ngIf="isProvider">
            <br *ngIf="isProvider">
          </section>

          <mat-error class="server-error">{{ serverMessage }}</mat-error>

          <button
            *ngIf="isPasswordReset"
            mat-stroked-button
            type="submit"
            [disabled]="loading"
          >
            Send Reset Email
          </button>

          <button
            *ngIf="!isPasswordReset"
            mat-raised-button
            color="accent"
            type="submit"
            [disabled]="form.invalid || !passwordDoesMatch || loading"
          >
            Submit
          </button>
        </form>

        <button
          mat-button
          *ngIf="isLogin && !isPasswordReset"
          (click)="changeType('reset')"
        >
          Forgot password?
        </button>
      </mat-card>



      <button mat-raised-button appGoogleSignin>
        <img src="/assets/google-logo.svg" /> {{  type == 'login' ? 'Login With Google' : 'Sign Up With Google' }}
      </button>

  </div>

  <div *ngIf="auth.user$| async as user" class="logout">
    <p>
      Logged in as <strong>{{ user.email }}</strong>
    </p>

    <button mat-stroked-button (click)="auth.signOut()">Logout</button>
  </div>
</main>

