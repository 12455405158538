import { FirestoreService } from 'src/app/services/firestore.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Device } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';
import { DeviceVO, OrderRequestApprovedState, OrderRequestVO, OrderVO } from './value-objects/order.vo';
import { NewAuthService } from '../auth/new-auth-service.service';

export const DEVICE_ORDERS_TABLE = 'hardware_orders';
export const DEVICE_ORDERS_REQUEST_TABLE = 'hardware_order_requests';

@Injectable({
  providedIn: 'root',
})
export class DeviceOrderingService {
  public manufacturerNames$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  public manufacturerDevices$: BehaviorSubject<DeviceVO[]> = new BehaviorSubject<DeviceVO[]>([]);

  private _devices: DeviceVO[];

  constructor(private db: AngularFirestore, private http: HttpClient, private fsSercie: FirestoreService, private auth: NewAuthService) {}

  orderDevice(order: OrderVO, orderId?: string): Observable<any> {
    order.orderId = orderId ? orderId : this.db.createId();
    const endpoint = environment.welbyEndpoint + `/api/v1/device/order/create/${order.manufacturerId.toLowerCase()}`;
    return this.http.post<any>(endpoint, order);
  }

  requestDevice(order: OrderVO): Promise<any> {
    let hardwareOrderRequest: OrderRequestVO = {
      request_body: order,
      id: this.db.createId(),
      requester_id: this.auth.user.user_id,
      client_responsible_id: this.auth.user.client_responsible_id,
      approved: OrderRequestApprovedState.PENDING,
      comments: '',
    };
    return this.fsSercie.set(`${DEVICE_ORDERS_REQUEST_TABLE}/${hardwareOrderRequest.id}`, hardwareOrderRequest);
  }

  updateOrderRequest(orderRequest: OrderRequestVO): Promise<any> {
    return this.fsSercie.update(`${DEVICE_ORDERS_REQUEST_TABLE}/${orderRequest.id}`, {
      approved: orderRequest.approved,
      comments: orderRequest.comments,
    });
  }

  getDeviceOrdersByUserId(client_id: string): Observable<OrderVO[]> {
    const available_clients = this.auth.user.client_accounts;
    if (this.auth.user.roles.isAdmin) {
      return this.fsSercie.col$(DEVICE_ORDERS_TABLE);
    } else {
      return this.fsSercie.col$(DEVICE_ORDERS_TABLE, (ref) => ref.where('clientId', 'in', available_clients));
    }
  }

  getDeviceOrdersByPatientId(patientId: string): Observable<OrderVO[]> {
    return this.db
      .collection<OrderVO>(DEVICE_ORDERS_TABLE, (ref) => ref.where('patient.id', '==', patientId))
      .valueChanges()
      .pipe(map((orders) => orders as OrderVO[]));
  }

  getOrderStatus(manufacturerId: string, orderId: string): Observable<any> {
    const endpoint = environment.welbyEndpoint + `/api/v1/device/order/status/${manufacturerId.toLowerCase()}/${orderId}`;
    return this.http.get<any>(endpoint);
  }

  getAllDevices(): Observable<DeviceVO[]> {
    if (this._devices) {
      return of(this._devices);
    } else {
      return this.db
        .collection('devices')
        .valueChanges()
        .pipe(
          take(1),
          map((devices) => devices as DeviceVO[]),
          tap((devices) => (this._devices = devices)),
          tap((devices) => this.manufacturerNames$.next([...new Set(devices.map((device) => device.mfg_display))].sort()))
        );
    }
  }

  getDevicesByManufacturer(manufacturer: string): Observable<DeviceVO[]> {
    return this.getAllDevices().pipe(
      map((devices) => devices.filter((device) => device.mfg_display === manufacturer)),
      tap((devices) => this.manufacturerDevices$.next(devices))
    );
  }

  loadDeviceToUserAccount(isNew: boolean, order: OrderVO, imei?: string) {
    const newDevice: Device = {
      ordered: false,
      delivered: false,
      active: false,
      auth_token: 'N/A',
      device_manufacturer: order.device.manufacturer,
      device_model: order.device.type,
      device_id: !!imei ? imei : 'N/A',
      imageURL: order.device.imageURL,
      last_update: new Date(),
      refresh_token: 'N/A',
      user_id: 0,
      order_notes: order.orderNotes,
    };

    const deviceMap = `${order.device.manufacturer}_${order.device.type}`;
    const ref = this.db.collection('users').doc(order.patient.id).collection('my_devices').doc(deviceMap);

    // just sending a generic email about the load.
    const requestMessage = {
      to: ['seth@getwelby.com'],
      message: {
        subject: 'New Device Request',
        text: `New request for ${order.patient.lastName}. Request for a ${order.device.manufacturer} ${order.device.type} to be ordered`,
        html: `New request for ${order.patient.lastName}. Request for a ${order.device.manufacturer} ${order.device.type} to be ordered`,
      },
    };

    if (isNew) {
      this.db.collection('device_requests').add(requestMessage);
      ref.set(newDevice);
    } else {
      ref.set(newDevice, { merge: true });
    }

    if (order.device.imei !== 'N/A') {
      return this.mapDeviceToUser(order.patient.id, newDevice);
    } else {
      return;
    }
  }

  mapDeviceToUser(uid: string, device: Device) {
    // maps the device to the user in the 'device_user_mapping' table
    const mapID = `${uid}_${device.device_id}`;
    this.db
      .collection('device_user_mapping')
      .doc(mapID)
      .set({
        device_id: device.device_id,
        device_mfg: device.device_manufacturer,
        user_id: uid,
        device_ref_id: device.device_manufacturer + '_' + device.device_model,
      });
  }
}
