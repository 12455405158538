import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { MatTableDataSource } from '@angular/material/table';
import { Component, OnInit } from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatDialogRef } from '@angular/material/dialog';
import { FirestoreService } from 'src/app/services/firestore.service';
import { CarePlanTemplateItem, PatientCase } from 'src/app/models/carereplan-model';
import { PatientDetailService } from 'src/app/services/patient-detail.service';
import { FormControl, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

// export interface PeriodicElement {
//   name: string;
//   position: number;
//   weight: number;
//   type: string;
// }

@Component({
  selector: 'app-careplan-new-dialog',
  templateUrl: './careplan-new-dialog.component.html',
  styleUrls: ['./careplan-new-dialog.component.scss']
})
export class CareplanNewDialogComponent implements OnInit {

  allTableData: CarePlanTemplateItem[] = [];
  filteredTableData: CarePlanTemplateItem[] = [];
  planItems$: Observable<CarePlanTemplateItem[]>;

  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  tags: string[] = [
    'diabetes',
    'obesity',
  ];

  tableIsVisible = false

  categories = ['Education', 'Goals', 'Problems', 'Outcomes', 'Interventions', ]

  displayedColumns: string[] = ['select', 'title', 'description'];
  dataSource = new MatTableDataSource<CarePlanTemplateItem>(this.allTableData);
  selection = new SelectionModel<CarePlanTemplateItem>(true, []);

  diagnoses = {
    l10: 'hypertension',
    hypertension: 'hypertension',
    e11: 'diabetes',
    diabetes: 'diabetes',
  }

  dx = new FormControl('', Validators.required);
  filteredOptions: Observable<string[]>;

  constructor(public dialogRef: MatDialogRef<CareplanNewDialogComponent>, private fsService: FirestoreService,
              private patient: PatientDetailService, private db: AngularFirestore) { }


  ngOnInit(): void {
    this.dataSource.data = this.filteredTableData
    this.filteredOptions = this.dx.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    const options = Object.keys(this.diagnoses)
    return options.filter(option => option.toLowerCase().includes(filterValue));
  }

  getTemplate() {
    this.allTableData = []
    this.selection.clear;
    const filterText = this.diagnoses[this.dx.value]
    this.planItems$ = this.fsService.colWithIds$(`care_plan_items`, (ref) => ref.where('diagnoses', 'array-contains', filterText ))

    this.planItems$.subscribe((items: CarePlanTemplateItem[]) => {
      items.forEach(i => {
        this.allTableData.push({
          title: i.title,
          description: i.description,
          type: i.type,
          link: i.link
        })
      });
    // default the education items for the first cut.
    this.dataSource.data = this.allTableData.filter(e => e.type == 'education');
    })
  }

  applyFilter($event) {
    const filterType = $event.tab.textLabel.toLowerCase();
    this.dataSource.data = this.allTableData.filter(e => e.type == filterType);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
    this.selection.clear() :
    this.dataSource.data.forEach(row => this.selection.select(row));
  }

  //CHIPS FOR SUBDIAGNOSES
  // add(event: MatChipInputEvent): void {
  //   const value = (event.value || '').trim();
  //   if (value) {
  //     this.tags.push(value.toLowerCase());
  //   }
  //   // Reset the input value
  //   if (event.input) {
  //     event.input.value = '';
  //   }
  // }

  // remove(tag: string): void {
  //   const index = this.tags.indexOf(tag);

  //   if (index >= 0) {
  //     this.tags.splice(index, 1);
  //   }
  // }

  toggleTable() {
    this.getTemplate();
    this.tableIsVisible = !this.tableIsVisible
  }

  async createCarePlan() {

    let planItems = [];
    this.selection.selected.forEach(e => {

      planItems.push({
        active: true,
        status: 'not started',
        date_opened: new Date(),
        title: e.title,
        description: e.description,
        type: e.type,
        link: e.link,
        on_care_plan: true
      })
    })

    const plan: PatientCase = {
      uid: this.patient.currentPatientUID,
      active: true,
      items: planItems,
      primary_dx: this.diagnoses[this.dx.value]
    }

    await this.db.collection('cases').ref.where('active', '==', true).get().then((snap) => {
      if (snap.empty) {
        console.log('empty snap');
      } else {
        snap.docs.forEach((doc) => {
          this.db.collection('cases').doc(doc.id).set({active: false}, {merge: true});
        });
      }
    });
    this.db.collection("cases").add(plan)
    this.dialogRef.close()
  }

  cancel() {
    this.selection.clear;
    this.dialogRef.close();
  }

}

