<h1 mat-dialog-title>Add Clinical Time</h1>
<div mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field style="width: 17%; margin-right: 3%">
      <input matInput type="number" placeholder="Mins" [(ngModel)]="data.time.mins" formControlName="mins" required />
    </mat-form-field>

    <mat-form-field style="width: 17%; margin-right: 3%">
      <input type="number" placeholder="Secs" matInput [(ngModel)]="data.time.secs" formControlName="secs" required />
    </mat-form-field>

    <mat-form-field style="width: 60%">
      <mat-label>Select</mat-label>
      <mat-select [(ngModel)]="data.time.category" formControlName="category">
        <mat-option *ngFor="let category of categories" [value]="category.value">
          {{ category.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <mat-checkbox [(ngModel)]="data.time.contact" formControlName="contact">Included Patient Contact</mat-checkbox>
    <br />
    <br />
    <mat-checkbox [(ngModel)]="data.time.excluded" formControlName="exclude">Exclude for concurrent E/M visit</mat-checkbox>
    <br />
  </form>
</div>
<div mat-dialog-actions fxLayout.xs="row" fxLayoutAlign="end">
  <button mat-raised-button color="primary" (click)="saveTime()" cdkFocusInitial [disabled]="form.invalid">Submit</button>
  <button mat-button (click)="cancel()">Cancel</button>
</div>
