import { AngularFirestore } from '@angular/fire/firestore';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { CareteamDialogComponent } from '../careteam-dialog/careteam-dialog.component';

@Component({
  selector: 'app-alert-contact-dialog',
  template: `
  <form [formGroup]="alertDataForm" >
    <h1 mat-dialog-title>{{ data.isNew ? "Add New Contact" : "Edit Contact" }}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:63%; margin-right: 2%;">
        <mat-label>Contact Data</mat-label>
        <input matInput formControlName="contactData" [(ngModel)]="data.contactData.data" required/>
        <mat-error>Contact data is required</mat-error>
      </mat-form-field>

      <mat-form-field style="width:35%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="contactType" [(ngModel)]="data.contactData.type" required>
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="alertDataForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
  `,
  styles: []
})
export class AlertContactDialogComponent implements OnInit {

  alertDataForm: FormGroup;
  types = ['provider', 'patient']

  constructor(private dialogRef: MatDialogRef<CareteamDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder, private patientService: PatientService, private db: AngularFirestore) { }

  ngOnInit(): void {
    this.alertDataForm = this.fb.group ({
      contactData: ['', [Validators.required]],
      contactType: ['', [Validators.required]],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
