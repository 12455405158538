import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  public isLightMode: boolean;
  private isLightModeSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isLightModeObservable$: Observable<boolean> = this.isLightModeSubject.asObservable();

  private renderer: Renderer2;

  constructor(@Inject(DOCUMENT) private document: Document, rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public toggleDarkMode() {
    this.isLightMode = !this.isLightMode;
    this.isLightModeSubject.next(this.isLightMode);

    if (this.isLightMode) {
      this.renderer.addClass(this.document.body, 'lightMode');
    } else {
      this.renderer.removeClass(this.document.body, 'lightMode');
    }
  }
}
