import { MeasureValueService } from '../../../services/measures/measure-value.service';
import { PatientFlagCriteria } from 'src/app/models/vitals/vital-flag.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';
import { Chart } from 'chart.js';
import { Component, OnInit, Input, SimpleChanges, DoCheck, OnChanges } from '@angular/core';
import * as ChartAnnotation from 'chartjs-plugin-annotation';

@Component({
  selector: 'app-primary-chart',
  templateUrl: './primary-chart.component.html',
  styleUrls: ['./primary-chart.component.scss'],
})
export class PrimaryChartComponent implements OnInit, OnChanges, DoCheck {
  //dualTriggerMeasure: boolean;
  activeChart: Chart;
  chart = [];
  singleChart = [];
  canvas: any;
  ctx: any;
  one_day = 1000 * 60 * 60 * 24; // in milliseconds

  @Input() chartType: string;
  @Input() passedMeasureName: string;
  @Input() passedChartValues: VitalRecord[];
  @Input() passedFlags: PatientFlagCriteria[];
  @Input() startDate: Date;
  @Input() endDate: Date;

  viewInitialized = false;

  constructor(private ms: MeasureValueService) {}

  ngOnInit(): void {
    const namedChartAnnotation = ChartAnnotation;
    namedChartAnnotation['id'] = 'annotation';
    Chart.pluginService.register(namedChartAnnotation);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.viewInitialized && this.passedChartValues) {
      this.generateSingleChart(this.passedChartValues);
    }
  }

  ngDoCheck(): void {
    if (!this.viewInitialized) {
      // run this before the canvas is actually rendered to try to update the chart.
      this.canvas = document.getElementById('myChart');
    }
    if (this.canvas!! && !this.viewInitialized && this.passedChartValues) {
      this.generateSingleChart(this.passedChartValues);
      this.viewInitialized = true;
    }
  }

  formatDate(date) {
    const d = new Date(date);
    const timespan = (this.endDate.getTime() - this.startDate.getTime()) / this.one_day - 1;
    // let month = '' + (d.getMonth() + 1);
    let day = d.toLocaleDateString([], { weekday: 'short', month: 'numeric', day: 'numeric' });
    let hour = '' + d.toLocaleTimeString([], { hour: 'numeric' });
    if (timespan < 10) {
      return day + ' - ' + hour;
    } else {
      return d.toLocaleDateString([], { month: 'numeric', day: 'numeric', year: '2-digit' });
    }
  }

  generateSingleChart(values: VitalRecord[]) {
    if (this.activeChart != null) {
      // this just prevents an error from an empty chart on loading.
      this.activeChart.config.data = {};
    }

    const triggerLines = [];
    const dates = [];
    const dataSets = [];
    const fillVariable = this.chartType === 'line' ? false : true;

    const params = this.ms.returnValueTypes(this.passedMeasureName).params;
    const chartData = {};

    for (const p of params) {
      chartData[p] = [];
    }

    for (const value of values) {
      //first push the dates for the x axis
      const displayDate = this.formatDate(value.measureDate.toDate());
      dates.push(displayDate);
      // then push the individual data by type
      for (const p of params) {
        value.value!! ? chartData[p].push(Math.round(value.value[p])) : chartData[p].push(Math.round(value.value1));
      }
    }

    const colors = ['#fbb465', '#fcb423', '#547D8A'];

    //push the data per type to the chart data
    for (const [i, p] of params.entries()) {
      dataSets.push({
        data: chartData[p],
        borderColor: colors[i],
        borderWidth: 1.75,
        pointBorderWidth: 0.75,
        pointRadius: 2.75,
        pointHoverRadius: 6.0,
        pointHoverBorderWidth: 2.0,
        fill: fillVariable,
      });
    }

    for (const f of this.passedFlags) {
      triggerLines.push({
        type: 'line',
        mode: 'horizontal',
        scaleID: 'y-axis-0',
        value: f.offset_amount,
        borderColor: '#f05245',
        borderWidth: 0.75,
        label: {
          enabled: false,
          content: f.notes,
        },
      });
    }

    if (this.canvas!!) {
      this.ctx = this.canvas.getContext('2d');
      this.activeChart = new Chart(this.ctx, {
        type: this.chartType,
        data: {
          labels: dates,
          datasets: dataSets,
        },
        options: {
          responsive: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: false,
                },
                ticks: {
                  fontSize: 11,
                  fontColor: '#ffffff',
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: true,
                  lineWidth: 0.25,
                  color: '#949b96',
                  zeroLineWidth: 1.0,
                  zeroLineColor: '#ffffff',
                },
                ticks: {
                  fontSize: 11,
                  fontColor: '#ffffff',
                },
              },
            ],
          },
          tooltips: {
            mode: 'index',
            intersect: true,
          },
          annotation: {
            annotations: triggerLines,
          },
        },
      });
    }
  }
}
