import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DocumoService } from 'src/app/services/communications/documo.service';
import { SnackService } from 'src/app/services/snack.service';
import { phoneNumberValidator } from 'src/app/shared/validators/phone-number.validator';

@Component({
  selector: 'app-send-fax-dialog',
  templateUrl: './send-fax-dialog.component.html',
  styleUrls: ['./send-fax-dialog.component.scss'],
})
export class SendFaxDialogComponent implements OnInit {
  sendFaxForm: FormGroup;
  loading: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<SendFaxDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private faxService: DocumoService,
    private snackBarService: SnackService
  ) {}

  ngOnInit(): void {
    this.sendFaxForm = this.fb.group({
      recipientName: ['', Validators.required],
      faxNumber: ['', [Validators.required, Validators.maxLength(12), phoneNumberValidator]],
      subject: ['', Validators.required],
      senderName: ['', Validators.required],
    });
  }

  sendFax(): void {
    this.loading = true;
    let sendData = this.sendFaxForm.getRawValue();
    sendData.faxNumber = `+1${sendData.faxNumber.replace(/-/g, '')}`;
    this.faxService.sendFileFax({ ...sendData, filePath: this.data.url }).subscribe(
      () => this.snackBarService.genericSnackBar('Fax sent successfully!', ['success-snackbar']),
      () => this.snackBarService.genericSnackBar('Error has ocurred ', ['error-snackbar']),
      () => {
        this.loading = true;
        this.dialogRef.close();
      }
    );
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
