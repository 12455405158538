import { Injectable } from '@angular/core';
import { Patient } from 'src/app/models/patient.model';
import { NewAuthService } from '../auth/new-auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class RoleModel {

  constructor(
    private auth: NewAuthService
  ) { }

  isAdmin(user?: Patient): boolean {
    const allowed = ['isAdmin'];
    return this.checkAuthorization(user ? user : this.auth.user, allowed);
  }

  isPracticeAdmin(user: Patient): boolean {
    if (!!user) {
      const allowed = ['isPracticeAdmin'];
      return this.checkAuthorization(user, allowed);
    }

  }

  isClient(user: Patient): boolean {
    if (!!user) {
      const allowed = ['isClient', 'isAdmin'];
      return this.checkAuthorization(user, allowed);
    }
  }

  isPatient(user: Patient): boolean {
    const allowed = ['isPatient'];
    return this.checkAuthorization(user, allowed);
  }

  private checkAuthorization(user: Patient, allowedRoles: string[]): boolean {
    if (!user) { return false; }
    for (const role of allowedRoles) {
      if (user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
