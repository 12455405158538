import { Component, ElementRef, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CameraComponent } from 'src/app/camera/camera.component';
import { LocalAudioTrack, LocalTrack, LocalVideoTrack, RemoteParticipant, Room } from 'twilio-video';

import { ClinicalResolution } from '../models/clinical.model';
import { AppointmentEvent } from '../models/event.model';
import { Patient, PatientContact } from '../models/patient.model';
import { ClientAppointment } from '../models/video.model';
import { ParticipantsComponent } from '../participants/participants.component';
import { NewAuthService } from '../services/auth/new-auth-service.service';
import { FirestoreService } from '../services/firestore.service';
import { PatientService } from '../services/patient.service';
import { VideoChatService } from '../services/video-chat.service';
import { SettingsComponent } from '../settings/settings.component';

export interface Tile {
  color: string;
  cols: number;
  rows: number;
  text: string;
}



@Component({
  selector: 'app-video-chat',
  templateUrl: './video-chat.component.html',
  styleUrls: ['./video-chat.component.scss']
})
export class VideoChatComponent implements OnInit, OnDestroy {
  @ViewChild('camera') camera: CameraComponent;
  @ViewChild('settings') settings: SettingsComponent;
  @ViewChild('participants') participants: ParticipantsComponent;
  @ViewChild('preview') previewElement: ElementRef;

  tiles: Tile[] = [
    {text: 'One', cols: 4, rows: 6, color: 'lightblue'},
    {text: 'Two', cols: 2, rows: 3, color: 'lightgreen'},
    {text: 'Three', cols: 2, rows: 5, color: 'lightgreen'},
    {text: 'Four', cols: 1, rows: 2, color: 'lightpink'},
    {text: 'Five', cols: 3, rows: 2, color: '#DDBDF1'},
  ];

  activeRoom: Room;
  patient: Patient;
  roomID: string;
  clientID: string;
  isWaiting = false;

  roomStatus = 'closed';

  apptCollectionRef: AngularFirestoreCollection<ClientAppointment>;
  eventCollectionRef: AngularFirestoreCollection<AppointmentEvent>;
  appointments$: Observable<ClientAppointment[]>;
  activeAppointment$: Observable<ClientAppointment>;
  activeAppointment: ClientAppointment;
  displayedColumns = ['date', 'status', 'join'];
  apptList = new MatTableDataSource();
  patientContact: string;
  patientActivated = false;

  currentUser: Patient;

  form: FormGroup;
  loading = false;
  serverMessage: string;


  resolutions: ClinicalResolution[];
  haveHistory = false;

  private videoTrack: LocalVideoTrack;
  private localTracks: LocalTrack[] = [];
  constructor(public readonly videoChatService: VideoChatService, private patientService: PatientService, public auth: NewAuthService,
              private db: AngularFirestore, private readonly fireFns: AngularFireFunctions, private fsService: FirestoreService,
              private fb: FormBuilder, private router: Router, private readonly renderer: Renderer2) { }

  ngOnInit() {
    this.currentUser = this.auth.user;
    this.clientID = this.auth.user.client_responsible_id;
    this.eventCollectionRef = this.db.collection('events');
    this.getAllAppointments();


    this.form = this.fb.group({
      content: ['', [Validators.required]],
    });
  }

  getAllAppointments() {

    this.apptCollectionRef = this.db.collection('clients').doc(this.clientID).collection('appointments');

    if (this.currentUser.roles.isClient) {
      this.appointments$ = this.fsService.colWithIds$(`clients/${this.clientID}/appointments`);
    } else {
      // this.db.col$('notes', ref => ref.where('user', '==', 'Jeff'))
      this.appointments$ = this.fsService.colWithIds$(`clients/${this.currentUser.client_responsible_id}/appointments`, ref =>
        ref.where('patient_id', '==', this.currentUser.user_id));
    }
    this.appointments$.subscribe((appt) => {
      this.apptList.data = appt;
    });
  }



  // grab the patient cell number
  updatePatientContactNumber(uid: string) {
    console.log('trying top update cell', uid);
    const contactRef = this.db.collection('users').doc(uid).collection('my_contacts', (ref) => ref
      .where('contact_type', '==', 'cellular'));
    contactRef.valueChanges().subscribe((contactRecord: PatientContact[]) => {
      if (contactRecord.length > 0) {
        this.patientContact = contactRecord[0].contact_data;
        console.log('cell', this.patientContact);
      }
      return;
    });
  }


  async subscribeToAppointment(apptID: string, provider?: boolean, patient?: boolean) {

    this.activeAppointment$ = this.fsService.doc$(`clients/${this.clientID}/appointments/${apptID}`);
    this.videoChatService.activeAppointment$ = this.fsService.doc$(`clients/${this.clientID}/appointments/${apptID}`);
    // this.camera.finalizePreview();
    const videoDevice = this.settings.hidePreviewCamera();
    this.camera.initializePreview(videoDevice);
    this.participants.clear();
    this.activeAppointment$.subscribe((appt: ClientAppointment) => {

      this.videoChatService.activeAppointmentID = apptID;
      this.activeAppointment = appt;
      if (appt.status.hasPatient && appt.status.hasProvider) {
        this.onRoomChanged(apptID);
      }
      console.log('appt on the chat component', appt, 'ID?', apptID);
    });
    this.updateItem(apptID, provider, patient);

  }

  updateItem(apptID: string, provider?: boolean, patient?: boolean) {
    // for doctors, they always turn off waiting and set the room to open/close
    if ( this.currentUser.roles.isClient ) {
      this.apptCollectionRef.doc(apptID).update({'status.hasProvider': provider})
        .catch(error => this.handleError(error));

    // for patients, they can just update the waiting status to trigger the waiting room
    } else {
      console.log('updating as patient');
      this.apptCollectionRef.doc(apptID).update({'status.hasPatient': patient})
          .catch(error => this.handleError(error));

      // if (!this.activeAppointment.status.isActive) {
      //   const patientCell = this.patientService.getPatientCell(this.activeAppointment.patient_id);
      //   this.sendReminderText(patientCell);
      // }
    }

// ADD THIS BACK IN******* THIS TRACKED THE ACTIVITY
//     if (active) {
//       this.eventCollectionRef.add({
//         uid: this.auth.activeUser.user_id,
//         client_id: this.auth.activeUser.client_responsible_id,
//         type: 'enter',
//         timeStamp: new Date(),
//         association_type: 'appointment',
//         association_id: apptID
//       });
//     } else {
//       this.eventCollectionRef.add({
//         uid: this.auth.activeUser.user_id,
//         client_id: this.auth.activeUser.client_responsible_id,
//         type: 'exit',
//         timeStamp: new Date(),
//         association_type: 'appointment',
//         association_id: apptID
//       });
//     }

  }

  // Default error handling for all actions
  private handleError(error) {
    console.log('Handling an error', error);
  }


  async onSettingsChanged(deviceInfo: MediaDeviceInfo) {
    console.log('settings changes')
    await this.camera.initializePreview(deviceInfo);
  }

  async onLeaveRoom(_: boolean) {

    this.activeAppointment$ = null;
    this.videoChatService.activeAppointment$ = null;

    if (this.activeRoom) {
      this.activeRoom.disconnect();
      this.activeRoom = null;
    }


    // const videoDevice = this.settings.hidePreviewCamera();
    this.camera.finalizePreview();
  //   console.log("video device",videoDevice)
  // this.camera.initializePreview(videoDevice);
    this.participants.clear();
  //   this.router.navigateByUrl("clinical");
  // LocalTrack.mediaStreamTrack.stop();

  }

  async onRoomChanged(roomName: string) {
    // console.log('active room', roomName);
    if (roomName) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }

      const tracks = this.camera.tracks;

      this.activeRoom =
        await this.videoChatService.joinOrCreateRoom(roomName, tracks);

      this.participants.initialize(this.activeRoom.participants);
      this.registerRoomEvents();
    }
  }

  onParticipantsChanged(_: boolean) {
    this.videoChatService.nudge();
  }

  private registerRoomEvents() {
    this.activeRoom
      .on('disconnected',
          (room: Room) => room.localParticipant.tracks.forEach(publication => this.detachLocalTrack(publication.track)))
      .on('participantConnected',
          (participant: RemoteParticipant) => this.participants.add(participant))
      .on('participantDisconnected',
          (participant: RemoteParticipant) => this.participants.remove(participant))
      .on('dominantSpeakerChanged',
          (dominantSpeaker: RemoteParticipant) => this.participants.loudest(dominantSpeaker));
  }

  private detachLocalTrack(track: LocalTrack) {
    console.log('trying to dc tracks');
    if (this.isDetachable(track)) {
      console.log('test', track);
      track.detach().forEach(el => el.remove());
    }
  }

  private isDetachable(track: LocalTrack): track is LocalAudioTrack | LocalVideoTrack {
    return !!track
      && ((track as LocalAudioTrack).detach !== undefined
      || (track as LocalVideoTrack).detach !== undefined);
  }

  sendReminderText( contactNumber: string ) {
    // const cell = this.patientService.getPatientCell();
    const callable = this.fireFns.httpsCallable('sendText');
    const authSend = callable({
      number: contactNumber,
      message: 'Your provider just opened your video room. Click http://app.getwelby.com to join now'
    });
    return authSend.toPromise();
  }

  ngOnDestroy(): void {
    this.onLeaveRoom(true);
  }

  get content() {
    return this.form.get('content');
  }

  async loadResolution(withTask: boolean, appt: ClientAppointment ) {
        this.loading = true;
        const content = this.content.value;
        console.log('appt on the clinical video', appt, this.auth.user, 'ID', this.videoChatService.activeAppointmentID);

        const newRes: ClinicalResolution = {
          content: this.content.value,
          date: new Date(),
          associations: {
            appointment_id: this.videoChatService.activeAppointmentID,
            uid: appt.patient_id
          },
        };

        try {
          this.patientService.addClinicalResolution(newRes, true, withTask);
          console.log('test content', content);
        } catch (err) {
          this.serverMessage = err;
        }
        this.loading = false;
  }

}
