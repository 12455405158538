<form [formGroup]="flagForm" >

  <h1 mat-dialog-title>{{ data.isNew ? "Create A New Vital Flag" : "Edit Flag" }}</h1>

  <div mat-dialog-content>

    <mat-form-field style="width:100%;">
      <mat-label>Category</mat-label>
      <mat-select formControlName="alert_category" [(ngModel)]="data.flag.alert_category" required>
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width:33%; margin-right: 2%;">
      <mat-label>Count</mat-label>
      <span matPrefix style="padding-right: 30px;">Check the last:  </span>
      <input placeholder="Count" matInput formControlName="count" [(ngModel)]="data.flag.count"/>
    </mat-form-field>

    <mat-form-field style="width:65%; ">
      <mat-label>Comparitor</mat-label>
      <mat-select formControlName="comparitor" [(ngModel)]="data.flag.comparitor" required>
        <mat-option *ngFor="let comparitor of comparitors" [value]="comparitor">
          {{ comparitor }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div style="padding: 20px 0px 10px 0px;">
      <h3>For each measure taken by the patient, if the measure is...</h3>
    </div>


    <mat-form-field style="width:13%; margin-right: 2%;">
      <mat-label>Operator</mat-label>
      <mat-select formControlName="operator" [(ngModel)]="data.flag.operator" required>
        <mat-option *ngFor="let operator of operators" [value]="operator">
          {{ operator }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field style="width:38%; margin-right: 2%;">
      <mat-label>Offset Type</mat-label>
      <span matPrefix style="padding-right: 20px;">that average</span>
      <mat-select formControlName="offset_type" [(ngModel)]="data.flag.offset_type" required>
        <mat-option *ngFor="let type of offset_types" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width:18%; margin-right: 2%;">
      <mat-label>Offset Amount</mat-label>
      <input placeholder="Offset Amount" matInput formControlName="offset_amount" [(ngModel)]="data.flag.offset_amount"/>
    </mat-form-field>

    <mat-form-field style="width:25%;">
      <mat-label>Comparitor Type</mat-label>
      <mat-select formControlName="comparitor_type" [(ngModel)]="data.flag.comparitor_type" required>
        <mat-option *ngFor="let comparitor of comparitor_types" [value]="comparitor">
          {{ comparitor }}
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field style="width:100%;">
      <span matPrefix style="padding-right: 20px;">Then trigger a</span>
      <span matSuffix style="padding-left: 20px;">alert</span>
      <mat-select formControlName="alert_type" [(ngModel)]="data.flag.alert_type" required>
        <mat-option *ngFor="let type of types" [value]="type">
          {{ type }}
        </mat-option>
      </mat-select>
    </mat-form-field>






    <!-- <mat-form-field style="width:23%; margin-right: 2%;">
      <mat-label>Select</mat-label>
      <mat-select formControlName="manufacturer" [(ngModel)]="data.device.device_manufacturer" required>
        <mat-option *ngFor="let manufacturer of manufacturers" [value]="manufacturer.value">
          {{ manufacturer.viewValue }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- <mat-form-field style="width:38%; margin-right: 2%">
      <mat-label>Select</mat-label>
      <mat-select formControlName="type" [(ngModel)]="data.device.device_model" required>
        <mat-option *ngFor="let model of availableModels[this.flagForm.get('manufacturer').value]" [value]="model">
          {{ modelViewValues[model] }}
        </mat-option>
      </mat-select>
    </mat-form-field> -->

    <!-- <mat-form-field style="width:35%">
      <mat-label>Device ID</mat-label>
      <input placeholder="Enter Device ID" matInput formControlName="device_id" [(ngModel)]="data.device.device_id"/>
      <mat-hint>Leave blank if not ordered</mat-hint>
    </mat-form-field> -->






    <div style="width: 100%;">
      <mat-slide-toggle style="width:49%; margin-right: 2%;"
          [(ngModel)]="data.flag.active" formControlName="active">
            Active
      </mat-slide-toggle>
      <mat-slide-toggle style="width:49%"
        [(ngModel)]="data.flag.secondary" formControlName="secondary">
          Secondary Value
      </mat-slide-toggle>

    </div>

    <div style="width: 100%; padding: 10px 0px;" >
      <mat-slide-toggle style="width:49%; margin-right: 2%;"
        [(ngModel)]="data.flag.alert_provider" formControlName="alert_provider">
          Alert Provider
      </mat-slide-toggle>
      <mat-slide-toggle style="width:49%"
          [(ngModel)]="data.flag.alert_patient" formControlName="alert_patient">
            Alert Patient
      </mat-slide-toggle>
    </div>

  </div>

  <div mat-dialog-actions>
    <div fxFlex></div>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="flagForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
      Update
    </button>
  </div>
</form>
