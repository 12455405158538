import { VitalFlagDialogComponent } from './../dialogs/patient/vital-flag-dialog/vital-flag-dialog.component';
import { CarePlanProfileQuestion } from './../models/carereplan-model';
import { CareTeamMember } from './../models/clinical.model';
import { AlertContact } from 'src/app/models/patient.model';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { PatientService } from './patient.service';
import { InsuranceDialogComponent } from '../dialogs/patient/insurance-dialog/insurance-dialog.component';
import { CareplanDialogComponent } from '../dialogs/patient/careplan-dialog/careplan-dialog.component';
import { ClientContact } from '../models/client.model';
import { ClientContactDialogComponent } from '../dialogs/practice/client-contact-dialog/client-contact-dialog.component';
import { PracticeService } from './practice.service';
import { CareteamDialogComponent } from '../dialogs/patient/careteam-dialog/careteam-dialog.component';
import { AlertContactDialogComponent } from '../dialogs/patient/alert-contact-dialog/alert-contact-dialog.component';
import * as firebase from 'firebase';
import { CarePlanItem } from '../models/carereplan-model';
import { CareplanProfileDialogComponent } from '../dialogs/patient/careplan-profile-dialog/careplan-profile-dialog.component';
import { CareplanNewDialogComponent } from '../dialogs/patient/careplan-new-dialog/careplan-new-dialog.component';
import { PatientFlagCriteria } from '../models/vitals/vital-flag.model';
import { VitalFlagWizardDialogComponent } from '../clinical/patient-detail/patient-page/tables/vital-flag-criteria/dialogs/vital-flag-wizard-dialog/vital-flag-wizard-dialog.component';
import { Breakpoints, BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    public dialog: MatDialog,
    private patientService: PatientService,
    private practiceService: PracticeService,
    private db: AngularFirestore,
    private breakpointObserver: BreakpointObserver
  ) {}

  //CARE PLANS
  newCarePlanDialog(): void {
    const dialogRef = this.dialog.open(CareplanNewDialogComponent, {
      width: '1200px',
      data: {},
    });
  }

  openCarePlanDialog(isNew: boolean, type: string, planItem?: CarePlanItem, caseID?: string): void {
    const dialogRef = this.dialog.open(CareplanDialogComponent, {
      width: '750px',
      data: planItem
        ? {
            planItem: {
              active: planItem.active,
              date_opened: planItem.date_opened.toDate(),
              description: planItem.description,
              link: planItem.link,
              on_care_plan: planItem.on_care_plan,
              status: planItem.status,
              title: planItem.title,
              type: planItem.type,
            },
            isNew: isNew,
          }
        : {
            planItem: {
              active: true,
              date_opened: new Date(),
              description: '',
              link: '',
              on_care_plan: true,
              status: 'not started',
              title: '',
              type: 'problems',
            },
            isNew: isNew,
          },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedPlanItem: CarePlanItem = {
          active: result.planItem.active,
          date_opened: result.planItem.date_opened,
          description: result.planItem.description,
          link: result.planItem.link,
          on_care_plan: result.planItem.on_care_plan,
          status: result.planItem.status,
          title: result.planItem.title,
          type: result.planItem.type,
        };

        this.patientService.editCaseItem(caseID, updatedPlanItem, planItem);
      }
    });
  }

  openCarePlanProfileDialog(isNew: boolean, profile?: CarePlanProfileQuestion[]): void {
    const dialogRef = this.dialog.open(CareplanProfileDialogComponent, {
      width: '750px',
      maxWidth: '100vw',
      data: profile ? { profile: profile, isNew: isNew } : { profile: [], isNew: isNew },
    });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedObject = {};
        const uid = this.patientService.currentPatientServiceID;

        result.profile.forEach((q) => {
          updatedObject[q.question_id] = q;
        });

        console.log('test', updatedObject);

        this.db.collection('patient_profiles').doc(uid).set(updatedObject, { merge: true });
      }
      subscription.unsubscribe();
    });
  }

  openCareTeamDialog(isNew: boolean, teamMember?: CareTeamMember): void {
    const dialogRef = this.dialog.open(CareteamDialogComponent, {
      width: '600px',
      maxWidth: '100vw',
      data: teamMember
        ? { teamMember: { ...teamMember }, isNew: isNew }
        : {
            teamMember: {
              active: true,
              first_name: '',
              last_name: '',
              degree: '',
              organization: '',
              profile_image_url: '',
              provider_id: '',
              speciality: '',
              type: 'other',
              phone: '',
              email: '',
            },
            isNew: isNew,
          },
    });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedMember: CareTeamMember = {
          active: true,
          first_name: result.teamMember.first_name,
          last_name: result.teamMember.last_name,
          degree: result.teamMember.degree,
          organization: result.teamMember.organization,
          profile_image_url:
            'https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/profile_images%2Fdoctor_placeholder.png?alt=media&token=865e5202-8b04-47d5-9e99-2516ac30af81',
          provider_id: 'TEST',
          speciality: result.teamMember.speciality,
          type: result.teamMember.type,
          phone: result.teamMember.phone,
          email: result.teamMember.email,
        };

        if (result.isNew) {
          return this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_care_team').add(updatedMember);
        } else {
          return this.db
            .collection('users')
            .doc(this.patientService.currentPatientServiceID)
            .collection('my_care_team')
            .doc(teamMember.id)
            .set(updatedMember);
        }
      }
      subscription.unsubscribe();
    });
  }

  //CLINICAL ALERTS
  openAlertContactDialog(isNew: boolean, contactData?: AlertContact): void {
    const dialogRef = this.dialog.open(AlertContactDialogComponent, {
      width: '400px',
      data: contactData ? { contactData: { ...contactData }, isNew: isNew } : { contactData: { data: '', type: 'provider' }, isNew: isNew },
    });

    dialogRef.afterClosed().subscribe((result) => {
      const uid = this.patientService.currentPatientServiceID;
      if (result) {
        const updatedContact: AlertContact = {
          data: result.contactData.data,
          type: result.contactData.type,
        };

        if (result.isNew) {
          return this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) });
        } else {
          this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayRemove(contactData) });
          return this.db
            .collection('users')
            .doc(uid)
            .update({ alert_contacts: firebase.firestore.FieldValue.arrayUnion(updatedContact) });
        }
      }
    });
  }

  openClientContactDialog(isNew: boolean, client_id: string, contact?: ClientContact): void {
    const dialogRef = this.dialog.open(ClientContactDialogComponent, {
      width: '500px',
      data: contact
        ? // if I passed in a user object, this is updating.
          {
            contact: {
              name: contact.name,
              primary_phone: contact.primary_email,
            },
            client_id: client_id,
            isNew: false,
          }
        : // otherwise these set some default values.
          { contact: {}, client_id: client_id, isNew: true },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const updatedContact = {
          name: result.contact.name,
          primary_phone: result.contact.primary_phone,
          primary_email: result.contact.primary_email,
          role: result.contact.role,
          active: true,
          uid: 'tbd',
        };

        console.log('noew?', result.isNew, result);
        this.practiceService.handleClientContact(result.isNew, updatedContact, result.client_id);

        //this.createEditProvider( updatedPatient, result.isNew, result.provider.user_id );
      }
    });
  }

  //OTHER
  openCoverageDialog(): void {
    const dialogRef = this.dialog.open(InsuranceDialogComponent, {
      width: '500px',
      data: {},
    });
  }

  openVitalFlagDialog(isNew: boolean, flag?: PatientFlagCriteria) {
    const dialogRef = this.dialog.open(VitalFlagDialogComponent, {
      width: '600px',
      data: flag
        ? { flag: { ...flag }, isNew: isNew }
        : {
            flag: {
              active: true, // whether the flag is active
              alert_category: 'Weight',
              alert_patient: false, // whether an alert to patient should be sent
              alert_provider: true, // whether an alert to provider should be sent
              alert_type: 'negative',
              comparitor: 'measures', // whether you are comparing to a number of measures, a number of days or a fixed amount
              comparitor_type: 'percent', // whether the vital is either a pecent higher or a straight number higher
              count: String(2), // either the number of measures or the number of days to include in the average
              offset_amount: String(10), // the number of +/- when comparing a value
              offset_type: '+', //whether to add or subtract the value
              operator: '<', //comparing greater than, less than, etc
              secondary: false, //whether this need to compare the primary or secondary
            },
            isNew: isNew,
          },

    });

    console.log('flag', flag);


    dialogRef.afterClosed().subscribe((result) => {
      if (result) {

        const flagData: PatientFlagCriteria = {
          active: result.flag.active,
          alert_category: result.flag.alert_category,
          alert_patient: result.flag.alert_patient,
          alert_provider: result.flag.alert_provider,
          alert_type: result.flag.alert_type,
          comparitor: result.flag.comparitor,
          comparitor_type: result.flag.comparitor_type,
          count: Number(result.flag.count),
          notes: 'test notes',
          offset_amount: Number(result.flag.offset_amount),
          offset_type: result.flag.offset_type,
          operator: result.flag.operator,
          priority: 1,
          secondary: result.flag.secondary,
        };

        const flagRef = this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_vital_flags');
        if (result.isNew) {
          return flagRef.add(flagData);
        } else {
          return flagRef.doc(flag.id).set(flagData, {merge: true});
        }
      }
    });
  }

  openVitalFlagWizardDialog(): void {
    const dialogRef = this.dialog.open(VitalFlagWizardDialogComponent, { width: '800px', maxWidth: '100vw' });

    const subscription = this.isExtraSmall.subscribe((size) => {
      if (size.matches) {
        dialogRef.updateSize('100vw', '100vh');
      }
    });

    dialogRef.afterClosed().subscribe((resp) => {
      subscription.unsubscribe();
    });
  }
}
