import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VitalFlagComparitors } from '../../models/vital_flags.model';

@Component({
  selector: 'app-measure-frequency',
  templateUrl: './measure-frequency.component.html',
  styleUrls: ['./measure-frequency.component.scss'],
})
export class MeasureFrequencyComponent implements OnInit {
  @Input()
  measureFrequencyForm: FormGroup;

  @Input()
  disableFixedValue: boolean = true;

  comparitors: string[];
  constructor() {}

  ngOnInit(): void {
    this.setComparitors(this.disableFixedValue);
  }

  setComparitors(disableFixedValue: boolean): void {
    if (disableFixedValue) {
      this.comparitors = Object.values(VitalFlagComparitors)
        .filter((flag) => flag !== VitalFlagComparitors.FIXED)
        .map((value) => `${value.charAt(0).toLocaleUpperCase()}${value.substring(1).toLocaleLowerCase()}`);
    } else {
      this.comparitors = Object.values(VitalFlagComparitors).map(
        (value) => `${value.charAt(0).toLocaleUpperCase()}${value.substring(1).toLocaleLowerCase()}`
      );
    }
  }
}
