import { Component, Input, OnInit } from '@angular/core';
import { VitalFlagCategory } from 'src/app/clinical/patient-detail/patient-page/tables/vital-flag-criteria/models/vital_flags.model';
import { VitalRecord } from 'src/app/models/vitals/vital-measure';

@Component({
  selector: 'app-vital-measurement',
  templateUrl: './vital-measurement.component.html',
  styleUrls: ['./vital-measurement.component.scss'],
})
export class VitalMeasurementComponent implements OnInit {
  @Input() measure_type: VitalFlagCategory;
  @Input() measurement: any;

  vitalCategory = VitalFlagCategory;

  constructor() {}

  ngOnInit(): void {}
}
