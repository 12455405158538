<form class="form" [formGroup]="alertTypeForm">
  <h2>Choose the alert type</h2>
  <div class="form-row">
    <div class="form-radio-group-container">
      <mat-radio-group class="form-radio-group" aria-label="Select an option" formControlName="alert_type">
        <mat-radio-button *ngFor="let type of types" [value]="type.toLowerCase()">{{ type }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <ng-container *ngIf="isCustomTarget">
    <h2>Should we send a text message when this alert is triggered?</h2>
    <div class="form-row">
      <mat-slide-toggle formControlName="active"> Yes </mat-slide-toggle>
    </div>
  </ng-container>
</form>
