import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-case-item-dialog',
  template: `
  <h1 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane">Care Plan Item</h1>
  <div mat-dialog-content>

  <form [formGroup]="form">

    <mat-form-field style="width:100%;">
      <mat-label>Item Title</mat-label>
      <input matInput formControlName="title" [(ngModel)]="data.item.title" required/>
      <mat-error>Title is required</mat-error>
    </mat-form-field>

    <mat-form-field style="width:100%;">
      <mat-label>Item Description</mat-label>
      <input matInput formControlName="description" [(ngModel)]="data.item.description" required/>
      <mat-error>Description is required</mat-error>
    </mat-form-field>

    <mat-form-field style="width:34%; padding-right: 2%;">
      <mat-label>Item Type</mat-label>
      <mat-select [(ngModel)]="data.item.type" formControlName="type" required>
        <mat-option *ngFor="let t of type" [value]="t">
          {{ t }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width:34%; padding-right: 2%;">
      <mat-label>Item Status</mat-label>
      <mat-select [(ngModel)]="data.item.status" formControlName="status" required>
        <mat-option *ngFor="let s of status" [value]="s">
          {{ s }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-checkbox style="width: 28%;" [(ngModel)]="data.item.on_care_plan" formControlName="on_care_plan">
      Add To Care Plan
    </mat-checkbox>

    <mat-form-field style="width:100%;">
      <mat-label>Educational Link</mat-label>
      <input matInput formControlName="link" [(ngModel)]="data.item.link"/>
    </mat-form-field>



    <br>
  </form>

  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
    </button>
  </div>
  `,
  styles: [
  ]
})
export class CaseItemDialogComponent implements OnInit {

  form: FormGroup;
  status = ['not started', 'progressing', 'stuck', 'exceeding', 'completed']
  type = ['education', 'goals', 'problems', 'interventions']

  constructor(public dialogRef: MatDialogRef<CaseItemDialogComponent>, private fb: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any) { }


    ngOnInit() {
      this.form = this.fb.group({
        description: ['', [Validators.required]],
        link: ['', []],
        on_care_plan: [false, []],
        status: ['', []],
        title: ['', [Validators.required]],
        type: ['', [Validators.required]],
      });
    }

    onNoClick(): void {
      this.dialogRef.close();
    }

}
