<form class="send-fax-container" [formGroup]="sendFaxForm" (ngSubmit)="sendFax()">
  <h2>Send Fax</h2>
  <mat-form-field appearance="outline">
    <mat-label>Fax number</mat-label>
    <span class="send-fax-field-prefix" matPrefix>+1</span>
    <input placeholder="Enter the fax number" matInput formControlName="faxNumber" phoneMask />
    <mat-error *ngIf="sendFaxForm.get('faxNumber').errors?.phoneNumber">Please, follow the format (+1 ###-###-####)</mat-error>
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Recipient name</mat-label>
    <input placeholder="Enter the recipient name" matInput formControlName="recipientName" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Subject</mat-label>
    <input placeholder="Enter the subject" matInput formControlName="subject" />
  </mat-form-field>
  <mat-form-field appearance="outline">
    <mat-label>Sender name</mat-label>
    <input placeholder="Enter the sender name" matInput formControlName="senderName" />
  </mat-form-field>
  <div mat-dialog-actions fxFlex fxLayout="row" fxLayoutAlign="flex-end center" fxLayoutGap="10px">
    <button mat-button type="button" (click)="cancel()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="sendFaxForm.invalid" type="submit" cdkFocusInitial>Send</button>
    <mat-spinner [diameter]="16" class="mfa-buttons-spinner" *ngIf="loading"></mat-spinner>
  </div>
</form>
