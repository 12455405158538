<mat-sidenav-container class="sidenav-container">
  <!-- SIDENAV -->

  <mat-sidenav
    #drawer
    class="sidenav"
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="false"
  >
    <mat-toolbar>Menu</mat-toolbar>

    <mat-nav-list>
      <a mat-list-item routerLink="/" (click)="drawer.close()">Home</a>
      <a mat-list-item routerLink="/login" (click)="drawer.close()">Login</a>
      <a mat-list-item routerLink="/clinical" (click)="drawer.close()">Physician Portal</a>
      <a mat-list-item routerLink="/consumer" (click)="drawer.close()">Patient Portal</a>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- TOP TOOLBAR-->

    <mat-toolbar *ngIf="auth.showToolbar">
      <button type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()" *ngIf="isHandset$ | async">
        <!-- hides this button unless its on a handet size device rom the TS file-->

        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <img
        *ngIf="!(isLightMode$ | async); else lightLogo"
        class="logo"
        src="../../../assets/logo_white.png"
        (click)="data.setTabID(0)"
        routerLink="/signed-in-redirect"
      />
      <ng-template #lightLogo>
        <img class="logo" src="../../../assets/square_logo.png" (click)="data.setTabID(0)" routerLink="/signed-in-redirect" />
      </ng-template>
      <!-- <span class="logo" routerLink="/">Welby Health</span> -->
      <div *ngIf="!(isHandset$ | async)">
        <!-- This div now only display is the display is not classified as a handset -->
        <a mat-button *ngIf="roleModel.isClient(auth.user)" routerLinkActive="active" routerLink="/clinical">Clinical Dashboard </a>
        <!-- <a mat-button *ngIf="auth.isClient(auth.activeUser)" routerLink="/clinical/panel">Patient List</a> -->
        <a mat-button *ngIf="roleModel.isPracticeAdmin(auth.user)" routerLinkActive="active" routerLink="/practice">Practice Admin</a>
        <a mat-button *ngIf="roleModel.isPatient(auth.user)" routerLink="/consumer">My Health</a>
        <!-- <a mat-button *ngIf="auth.isAdmin(auth.activeUser)"  routerLink="/agora">Agora</a> -->
      </div>

      <span class="fill-space"></span>
      <div
        *ngIf="roleModel.isAdmin(auth.user) || roleModel.isClient(auth.user)"
        class="shell-search-patient"
        fxLayout="row"
        fxHide.xs="true"
      >
        <search-patient-autocomplete></search-patient-autocomplete>
      </div>
      <div fxLayout="row" fxLayoutGap="10px">
        <mat-icon>dark_mode</mat-icon>
        <mat-slide-toggle [formControl]="themeToggleControl"></mat-slide-toggle>
        <mat-icon>light_mode</mat-icon>
      </div>

      <!-- pushes everything over to the right -->

      <div *ngIf="!(isHandset$ | async)">
        <!-- This div now only display is the display is not classified as a handset -->
        <button mat-button [mat-menu-trigger-for]="about">About</button>
        <mat-menu #about="matMenu">
          <!-- <button mat-menu-item routerLink="/about">About Us</button>
          <button mat-menu-item routerLink="/faq">FAQ</button> -->
          <button mat-menu-item routerLink="/training">Training Materials</button>
          <button mat-menu-item routerLink="/license">Software License</button>
          <button mat-menu-item routerLink="/baa">BAA</button>
          <button mat-menu-item routerLink="/terms">Terms of Use</button>
          <button mat-menu-item>{{ versionStr }}</button>
        </mat-menu>

        <a mat-button *ngIf="roleModel.isAdmin(auth.user)" routerLink="/admin">Admin</a>
      </div>

      <!-- DROPDOWN MENU -->
      <button mat-icon-button *ngIf="auth.user$ | async as user" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>

      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngIf="roleModel.isAdmin(auth.user)" routerLink="/admin">Admin Tools</button>
        <button mat-menu-item *ngIf="auth.user$ | async as user" (click)="logout()">Logout</button>
        <!-- <a mat-button routerLink="/login" *ngIf="!afAuth.authState | async as user">🔑 {{  !(afAuth.authState | async) == true ? 'Login' : 'Logout' }}</a> -->
      </mat-menu>
    </mat-toolbar>

    <!-- TRANSCLUSION -->
    <!-- <ng-content style="padding-bottom: 10px;"></ng-content> -->
    <div class="shell-content-container">
      <ng-content></ng-content>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
