import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { PatientFlagCriteria } from 'src/app/models/vitals/vital-flag.model';
import { SnackService } from 'src/app/services/snack.service';
import { VitalFlagBloodPressureSecondary, VitalFlagOffsetTypes, VitalFlagTypes } from '../../models/vital_flags.model';
import { VitalFlagService } from '../../services/vital-flag.service';

@Component({
  selector: 'app-vital-flag-wizard',
  templateUrl: './vital-flag-wizard.component.html',
  styleUrls: ['./vital-flag-wizard.component.scss'],
})
export class VitalFlagWizardComponent implements OnInit {
  @Output()
  closeEmmiter = new EventEmitter<boolean>();

  isLinear: boolean = false;
  isCustomTarget: boolean = true;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fifthFormGroup: FormGroup;
  offset_types = Object.values(VitalFlagOffsetTypes);
  isExtraSmall$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    private _formBuilder: FormBuilder,
    private vitalFlagService: VitalFlagService,
    private snackBarService: SnackService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.firstFormGroup = this._formBuilder.group({
      alert_category: ['', Validators.required],
      secondary: [false, Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      comparitors: ['', Validators.required],
      count: [0],
    });
    this.thirdFormGroup = this._formBuilder.group({
      offset_amount: ['', Validators.required],
      offset_type: ['', Validators.required],
      turn_offset_type: [true],
      operator: ['', Validators.required],
    });
    this.fourthFormGroup = this._formBuilder.group({
      alert_type: [VitalFlagTypes.POSITIVE, Validators.required],
      active: [true, Validators.required],
    });
    this.fifthFormGroup = this._formBuilder.group({
      alert_provider: [true, Validators.required],
      alert_patient: [true, Validators.required],
    });
    this.firstFormGroup.get('secondary').value === true
      ? this.firstFormGroup.get('secondary').setValue(VitalFlagBloodPressureSecondary.DIASTOLIC_BP)
      : this.firstFormGroup.get('secondary').setValue(VitalFlagBloodPressureSecondary.SYSTOLIC_BP);
  }

  save(): void {
    this.firstFormGroup.get('secondary').value === VitalFlagBloodPressureSecondary.SYSTOLIC_BP
      ? this.firstFormGroup.get('secondary').setValue(false)
      : this.firstFormGroup.get('secondary').setValue(true);
    const vitalFlag: PatientFlagCriteria = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.getRawValue(),
      ...this.thirdFormGroup.getRawValue(),
      ...this.fourthFormGroup.getRawValue(),
      ...this.fifthFormGroup.getRawValue(),
    };
    delete vitalFlag.turn_offset_type;
    this.vitalFlagService
      .saveVitals(vitalFlag)
      .then((resp) => {
        this.snackBarService.genericSnackBar('New Flag added', ['success-snackbar']);
      })
      .catch((error) => this.snackBarService.genericSnackBar('Error creating flag'));
    this.closeEmmiter.emit(true);
  }
}
