import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-measure-type-dialog',
  template: `
  <form [formGroup]="measureTypeForm" >
    <h1 mat-dialog-title>{{ data.isNew ? "Add Measure Data" : "Edit Measure Data" }}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:63%; margin-right:2%;">
        <mat-label>Measure Type</mat-label>
        <mat-select formControlName="type" [(ngModel)]="data.measureType.type" (ngModelChange)="updateType($event)" required>
          <mat-option *ngFor="let type of typeNames" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-checkbox [checked]="rpm" (change)="updateRPMStatus($event.checked)" style="width: 20%;" formControlName="rpm"> -->
      <mat-checkbox style="width: 35%;" formControlName="rpm_tracking" [(ngModel)]="data.measureType.rpm_tracking">
        RPM Tracking
      </mat-checkbox>

      <mat-form-field style="width:32%; margin-right: 1%;">
        <mat-label>Low {{firstTriggerType}}</mat-label>
        <input matInput formControlName="trigger_low" [(ngModel)]="data.measureType.trigger_low" required/>
        <mat-error>Low trigger is required</mat-error>
      </mat-form-field>

      <mat-form-field style="width:32%; margin-right: 1%;">
        <mat-label>High {{firstTriggerType}}</mat-label>
        <input matInput formControlName="trigger_high" [(ngModel)]="data.measureType.trigger_high" required/>
        <mat-error>High trigger is required</mat-error>
      </mat-form-field>

      <mat-form-field style="width:33%">
        <mat-label>Alert Level {{firstTriggerType}}</mat-label>
        <input matInput formControlName="alert_1" [(ngModel)]="data.measureType.alert_1" required/>
        <mat-error>First name is reguired</mat-error>
      </mat-form-field>

      <mat-form-field style="width:32%; margin-right: 1%;" *ngIf="this.dualMeasure">
        <mat-label>Low {{secondTriggerType}}</mat-label>
        <input matInput formControlName="trigger_low_2" [(ngModel)]="data.measureType.trigger_low_2"/>
      </mat-form-field>

      <mat-form-field style="width:32%; margin-right: 1%;" *ngIf="this.dualMeasure">
        <mat-label>High {{secondTriggerType}}</mat-label>
        <input matInput formControlName="trigger_high_2" [(ngModel)]="data.measureType.trigger_high_2"/>
      </mat-form-field>

      <mat-form-field style="width:33%;" *ngIf="this.dualMeasure">
        <mat-label>Alert Level {{secondTriggerType}}</mat-label>
        <input matInput formControlName="alert_2" [(ngModel)]="data.measureType.alert_2"/>
      </mat-form-field>

    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="measureTypeForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
  `,
  styles: []
})
export class MeasureTypeDialogComponent implements OnInit {

  measureTypeForm: FormGroup;
  dualMeasure = false
  firstTriggerType = ''
  secondTriggerType = ''

  typeNames = ['Pulse', 'Blood Glucose', 'Blood Pressure', 'Weight']

  typeData = {
    'Blood Pressure': {primary: 'Systolic', secondary: 'Diastolic', units: 'mmHg'},
    'Blood Glucose': {primary: 'Glucose', secondary: 'N/A', units: 'mgDl'},
    'Pulse': {primary: 'Pulse', secondary: 'N/A', units: 'BPM'},
    'Weight': {primary: 'Weight', secondary: 'N/A', units: 'Lbs.'},
  }


  constructor(private dialogRef: MatDialogRef<MeasureTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder, private dataService: DataService) { }

  ngOnInit(): void {
    this.measureTypeForm = this.fb.group ({
      alert_1: ['', [Validators.required]],
      alert_2: ['', []],
      rpm_tracking: [false, [Validators.required]],
      trigger_high: ['', [Validators.required]],
      trigger_high_2: ['', []],
      trigger_low: ['', []],
      trigger_low_2: ['', []],
      type: ['', [Validators.required]],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  updateType(newValue) {
    if (newValue == 'Blood Pressure') {
      this.dualMeasure = true;
    } else {
      this.dualMeasure = false;
    }

    if (this.data.isNew) {
      // this.data.measureType.trigger_high = this.dataService.defaultValues[newValue]['high1']
      // this.data.measureType.trigger_high_2 = this.dataService.defaultValues[newValue]['high2']
      // this.data.measureType.trigger_low = this.dataService.defaultValues[newValue]['low1']
      // this.data.measureType.trigger_low_2 = this.dataService.defaultValues[newValue]['low2']
      // this.data.measureType.alert_1 = this.dataService.defaultValues[newValue]['alert1']
      // this.data.measureType.alert_2 = this.dataService.defaultValues[newValue]['alert2']
    }
    this.firstTriggerType = this.typeData[newValue].primary
    this.secondTriggerType = this.typeData[newValue].secondary
  }




}
