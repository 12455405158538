import { DataService } from 'src/app/services/data.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { FormArray, FormGroup, FormControl, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientAddress, InsuranceCoverage } from 'src/app/models/patient.model';


@Component({
  selector: 'app-insurance-dialog',
  template: `
  <h1 mat-dialog-title>Insurance Coverage</h1>
  <form [formGroup]="coverageForm">
    <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" fxFill>

        <mat-form-field fxFlex="40">
          <input
            matInput
            type="text"
            id="payor"
            formControlName="payor"
            class="form-control"
            placeholder="Payor Name"
          >
        </mat-form-field>

        <mat-form-field fxFlex="30">
          <input
            matInput
            type="text"
            id="id"
            formControlName="id"
            class="form-control"
            placeholder="Member ID"
          >
        </mat-form-field>

        <!-- <mat-form-field fxFlex="10">
          <mat-label>State</mat-label>
          <mat-select formControlName="newState">
            <mat-option *ngFor="let state of this.data.states" [value]="state">
              {{ state }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

        <button mat-raised-button color="primary" fxFlex="5" (click)="onHandleCoverage('add')">Add</button>

      </div>


      <div class="mat-row" style="padding-top: 10px;" formArrayName="coverages">
        <hr>
        <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" style="padding-top: 20px;" fxFill
          *ngFor="let coverageControl of controls; let i = index"
            [formGroupName]="i"
        >
          <mat-form-field fxFlex="40">
            <input type="text" class="form-control" matInput formControlName="currentPayor" />
          </mat-form-field>
          <mat-form-field fxFlex="30">
            <input type="text" class="form-control" matInput formControlName="currentID" />
          </mat-form-field>
          <!-- <mat-form-field fxFlex="10">
          <mat-label>State</mat-label>
          <mat-select formControlName="state">
            <mat-option *ngFor="let state of this.data.states" [value]="state">
              {{ state }}
            </mat-option>
          </mat-select>
        </mat-form-field> -->

          <!-- <button
            *ngIf="!this.addressForm.controls['addresses']['controls'][i]['pristine']"
            mat-icon-button
            style="max-width: 5%;"
            color="primary"
            (click)="onHandleAddress('edit', i)"
            fxFlex="5"
          >
            <mat-icon>check_circle</mat-icon>
          </button>
          <button
            *ngIf="this.coverageForm.controls['addresses']['controls'][i]['pristine']"
            mat-icon-button
            style="max-width: 5%;"
            color="warn"
            (click)="onHandleAddress('delete', i)"
            fxFlex="5">
            <mat-icon>highlight_off</mat-icon>
          </button> -->
        </div>
      </div>
      <hr>
    </div>

    <div mat-dialog-actions>
      <button mat-button type="button" (click)="onNoClick()">Close</button>
    </div>
  </form>
`,
styles: ['.invisible {display: block; visibility: hidden; height: 0; width: 0;}']
})

export class InsuranceDialogComponent implements OnInit {

  constructor(private fsService: FirestoreService, private patientService: PatientService, private db: AngularFirestore,
    public data: DataService, public dialogRef: MatDialogRef<InsuranceDialogComponent>, ) { }

  coverageForm: FormGroup;
  currentCoverages$: Observable<InsuranceCoverage[]>;
  currentCoverages = [];

  ngOnInit(): void {
  this.observeAddresses()
  this.onInitForm()
  }

  private observeAddresses(): void {
    this.currentCoverages$ = this.fsService.colWithIds$(`users/${this.patientService.currentPatientServiceID}/my_health_coverage`);
    this.currentCoverages$.subscribe((coverage: InsuranceCoverage[]) => {
    this.currentCoverages = coverage
    this.onInitForm()
    })
  }

  private onInitForm(): void {
    const payor = '';
    const id = '';
    const coverageList = new FormArray([]);

    this.coverageForm = new FormGroup({
      payor: new FormControl(payor, Validators.required),
      id: new FormControl(id),
      coverages: coverageList,
    })

    if (this.currentCoverages.length > 0) {
      this.currentCoverages.forEach(c => {
        coverageList.push(
          new FormGroup({
            currentPayor: new FormControl(c.payor),
            currentID: new FormControl(c.id),
          })
        )
      });
    }
  }

  get controls(): any { // a getter!
    return (<FormArray>this.coverageForm.get('coverages')).controls;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  onHandleCoverage(type: string, index?: number) {
    const userRef = this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_health_coverage')
    const arrayRecord = this.coverageForm.value['coverages'][index];
    console.log(this.coverageForm);


    switch (type) {
      case 'add':
        const newAddress: InsuranceCoverage = {
          payor: this.coverageForm.value['payor'],
          member_id: this.coverageForm.value['id'],
        }
        userRef.add(newAddress);
        break;

      // case 'edit':
      //   const updatedContact: PatientAddress = {
      //   address: arrayRecord.street,
      //     city: arrayRecord.city,
      //     state: arrayRecord.state,
      //     zip: arrayRecord.zip,
      //   }
      //   console.log('recoprd', arrayRecord);
      //   userRef.doc(arrayRecord.id).set(updatedContact, {merge: true})
      //   break;

      // case 'delete':
      //   userRef.doc(arrayRecord.id).delete();
      //   break;

      default:
      break;
    }

  }

}
