import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { DataService } from 'src/app/services/data.service';


@Component({
  selector: 'app-document-dialog',
  templateUrl: './document-dialog.component.html',
  styleUrls: ['./document-dialog.component.scss']
})
export class DocumentDialogComponent implements OnInit {

  documentForm: FormGroup;

  constructor(private dialogRef: MatDialogRef<DocumentDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public ds: DataService,
              private fb: FormBuilder, private http: HttpClient, public patientService: PatientService) { }

  ngOnInit(): void {
    this.documentForm = this.fb.group ({
      document_name: ['', Validators.required],
      document_type: ['', Validators.required],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
