import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { Patient } from 'src/app/models/patient.model';
import { environment } from 'src/environments/environment';
import { Md5 } from 'ts-md5';

import { TokenService } from './token.service';
import { CreateUserDto, UserFactory } from './user.factory';

@Injectable({
  providedIn: 'root'
})
export class NewAuthService {

  user: Patient;
  user$: Observable<Patient>;
  showToolbar = false;
  mfaResolver;

  private _authenticated: boolean = false;
  private _tempCreds: { email: string, password: string } = { email: '', password: '' };

  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private tokenService: TokenService,
    private userFactory: UserFactory,
    private httpClient: HttpClient,
    private router: Router
  ) {
    this.afAuth.auth.onAuthStateChanged(async user => {
      if (user) {
        console.log('user.multiFactor.enrolledFactors:', user.multiFactor.enrolledFactors)
        console.log('user.emailVerified:', user.emailVerified)
      }
    });
  }

  // check if user has mfa enabled
  isMfaEnabled(): boolean {
    return this.afAuth.auth.currentUser.multiFactor.enrolledFactors.length > 0
  }

  async resolveMFA(userCredential: firebase.auth.UserCredential) {
    if (this.mfaResolver) {
      await this.validateAuthentication(this._tempCreds.email, this._tempCreds.password, userCredential);
      return this.user;
    }
  }

  async signIn(email: string, password: string): Promise<Patient> {

    // throw an error if th euser is already authenticated
    if (this._authenticated) {
      throw new Error('Already authenticated');
    }

    // authenticate the user with angular fire
    let userCredential: firebase.auth.UserCredential
    try {
      userCredential = await this.afAuth.auth.signInWithEmailAndPassword(email, password);
    } catch (e) {
      console.log('e:', e);
      if (e.code === 'auth/multi-factor-auth-required') {
        this._tempCreds = { email, password };
        this.mfaResolver = e.resolver;
        this.router.navigateByUrl('/mfa?verify=true&resolve=true');
        return null;
      } else {
        throw e;
      }
    }

    await this.validateAuthentication(email, password, userCredential);

    return this.user;
  }

  async signUpAsProvider(email: string, password: string, dto: CreateUserDto, routeAfterSignUp: boolean = true): Promise<Patient> {
    if (this._authenticated) {
      throw new Error('Already authenticated');
    }

    // Create the user with angular fire
    const userCredential: firebase.auth.UserCredential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);

    dto.uid = userCredential.user.uid;
    dto.practice = 'none';
    dto.practiceAdmin = false;
    dto.provider = true;
    dto.patient = false;
    dto.pwHash = Md5.hashStr(password);

    // create user account and store in the database
    await this.userFactory.createUserAccount(email, dto);

    await this.validateAuthentication(email, password, userCredential, routeAfterSignUp);

    // search for the user by email in the "signup_requests" table and delete it
    await this.db.collection('signup_requests').ref.where('email', '==', email).get().then(snapshot => {
      snapshot.forEach(doc => {
        doc.ref.delete();
      });
    });

    return this.user;
  }

  async signUp(email: string, password: string, isProvider: boolean, dto: CreateUserDto, routeAfterSignUp: boolean = true): Promise<Patient> {

    if (this._authenticated) {
      throw new Error('Already authenticated');
    }

    // Create the user with angular fire
    const userCredential: firebase.auth.UserCredential = await this.afAuth.auth.createUserWithEmailAndPassword(email, password);

    if (isProvider) {
      dto.clientId = await this.userFactory.createNewClient(email, dto);
      dto.uid = userCredential.user.uid;
      dto.practiceAdmin = true;
      dto.provider = true;
      dto.patient = false;
      dto.pwHash = Md5.hashStr(password);
    } else {
      dto.uid = userCredential.user.uid;
      dto.practice = 'none';
      dto.practiceAdmin = false;
      dto.provider = false;
      dto.patient = true;
      dto.pwHash = Md5.hashStr(password);
    }

    // create user account and store in the database
    await this.userFactory.createUserAccount(email, dto);

    await this.validateAuthentication(email, password, userCredential, routeAfterSignUp);

    return this.user;
  }

  async validateAuthentication(email: string, password: string, userCredential: firebase.auth.UserCredential, routeAfterSignUp: boolean = true): Promise<Patient> {

    // add a 1 second wait to ensure the user is authenticated
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Store the user in a user variable
    const userDoc = await this.db.collection('users').doc(userCredential.user.uid).ref.get();
    this.user = userDoc.data() as Patient;

    userDoc.ref.set({ pw_hash: Md5.hashStr(password) }, { merge: true });

    // Store the access token in the local storage
    await this.tokenService.requestNewToken(email, Md5.hashStr(password));

    // get a user observable for live updates
    this.user$ = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.db.doc<Patient>(`users/${userCredential.user.uid}`).valueChanges();
        } else {
          return of(null);
        }
      })
    );

    // Set the authenticated flag to true
    this._authenticated = true;

    if (routeAfterSignUp) {
      // rout the user to the correct landing page
      this.doLandingPageRoute();
    }

    this.showToolbar = true;
    this._tempCreds = null;

    return this.user;
  }

  async signOut(): Promise<boolean> {
    await this.afAuth.auth.signOut();
    this.tokenService.destroyToken();
    this._authenticated = false;
    this.router.navigate(['login']);
    this.showToolbar = false;
    return true;
  }

  doLandingPageRoute() {
    if (this.user.roles.isClient) {
      this.router.navigateByUrl('clinical');
    } else if (this.user.roles.isPracticeAdmin) {
      this.router.navigateByUrl('practice');
    } else if (this.user.roles.isPatient) {
      this.router.navigate(['consumer', this.user.user_id]);
    }
  }

  sendVerificationEmailForProvider(email: string): Promise<void> {
    const baseUrl = window.location.href.match(/^(.*?\/\/.*?\/).*$/)[1];
    const url = `${baseUrl}new-provider-signup?step=2`;
    return this.afAuth.auth.sendSignInLinkToEmail(email, { url, handleCodeInApp: true });
  }

  sendVerificationEmail(email: string): Promise<void> {
    const baseUrl = window.location.href.match(/^(.*?\/\/.*?\/).*$/)[1];
    const url = `${baseUrl}login`;
    return this.afAuth.auth.sendSignInLinkToEmail(email, { url, handleCodeInApp: true });
  }

  resetPassword(email: string = null): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(email ?? this.user.email);
  }

  forgotPassword(): Promise<void> {
    return this.afAuth.auth.sendPasswordResetEmail(this.user.email);
  }

  signInUsingToken(uid): Observable<any> {
    // Renew token
    return this.httpClient.post(environment.welbyEndpoint + '/api/v1/auth/refresh-access-token', {
      accessToken: this.tokenService.getToken(), uid
    }).pipe(
      catchError(() =>
        // Return false
        this.signOut().then(() => false)
      ),
      switchMap((response: any) => {

        if (!response) {
          return this.signOut().then(() => false)
        }

        // Store the enw token
        this.tokenService.setToken(response.access_token);
        // Set the authenticated flag to true
        this._authenticated = true;
        // store the user object from the response in the user variable
        this.user = response.user;
        // get a user observable for live updates
        this.user$ = this.afAuth.authState.pipe(
          switchMap(user => {
            if (user) {
              return this.db.doc<Patient>(`users/${user.uid}`).valueChanges();
            } else {
              return of(null);
            }
          })
        );
        return of(true);
      })
    );
  }

  getAfAuthUser(): Promise<firebase.User> {
    return this.afAuth.user.toPromise();
  }

  check(): Observable<boolean> {
    // Check if the user is logged in
    if (this._authenticated) {
      this.showToolbar = true;
      return of(true);
    }

    // Check the access token availability
    if (!this.tokenService.getToken()) {
      this.showToolbar = false;
      return of(false);
    }

    // Check if the token is expired
    if (this.tokenService.tokenIsExpired()) {
      this.showToolbar = false;
      return of(false);
    }

    // Check if user is authenticated in firebase
    return this.afAuth.authState.pipe(
      take(1),
      switchMap(user => {
        if (user) {
          this.showToolbar = true;
          return this.signInUsingToken(user.uid);
        } else {
          this.showToolbar = false;
          return of(false);
        }
      })
    );
  }

}
