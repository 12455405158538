import { SnackService } from './../../../services/snack.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { Guid } from 'guid-typescript';
import { InitialDataModel } from './../../../services/models/initial-data-model.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { PatientService } from 'src/app/services/patient.service';
import { Router } from '@angular/router';
import { Patient } from 'src/app/models/patient.model';
import { ageValidator } from 'src/app/shared/validators/patient-age.validators';
import { environment } from 'src/environments/environment';
import { ClientServicesService } from 'src/app/services/admin/client-services.service';

@Component({
  selector: 'app-patient-demographics-dialog',
  templateUrl: './patient-demographics-dialog.component.html',
  styleUrls: ['./patient-demographics-dialog.component.scss'],
})
export class PatientDemographicsDialogComponent implements OnInit {
  // THIS IS THE NEW MINIMIZED PATIENT LOADING FORM.
  patientForm: FormGroup;
  serverMessage: '';
  owners = [];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<PatientDemographicsDialogComponent>,
    private dataService: DataService,
    public dataModel: InitialDataModel,
    public ds: DataService,
    private auth: NewAuthService,
    private afAuth: AngularFireAuth,
    private snack: SnackService,
    private patientService: PatientService,
    private router: Router,
    private clientService: ClientServicesService
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.owners = Object.values(this.dataModel.clientProviderPanel$);
    debugger
    this.owners = this.owners.filter((u) => u.roles.isClient);
  }

  async loadPatientToDatabase() {
    this.isLoading = true;
    const patient = this.buildPatient();
    const canSavePatient = await this.canSavePatientAfterBillingRestriction();
    canSavePatient ? this.savePatient(patient) : this.showErrorNotification();
  }

  private initializeForm(): void {
    this.patientForm = this.fb.group({
      client_id: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dob: ['', [Validators.required, ageValidator]],
      email: ['', [Validators.email]],
      provider_id: ['', [Validators.required]],
      emr_id: ['', []],
      ssn_id: ['', [Validators.minLength(4), Validators.maxLength(4)]],
      notes: ['', []],
      rpm: ['', []],
      alert_sms: ['', [Validators.minLength(10), Validators.pattern('[0-9]*')]],
    });
  }

  private buildPatient(): Patient {
    const uid = Guid.create().toString();
    const userEmail = this.patientForm.value['email']!! ? this.patientForm.value['email'] : `${uid}@getwelby.com`;
    const firstName = this.patientForm.value.firstName;
    const lastName = this.patientForm.value.lastName;

    console.log(firstName);

    const patient = {
      user_account_access: [this.auth.user.user_id],
      client_accounts: [this.auth.user.client_responsible_id],
      alert_contacts: [{ type: 'provider', data: '+18584493348' }],
      client_responsible: this.dataModel.clients[this.patientForm.value.client_id].client_name,
      client_responsible_id: this.patientForm.value.client_id,
      provider_id: this.patientForm.value.provider_id,
      dob: new Date(this.patientForm.value.dob),

      email: userEmail,
      emr_id: this.patientForm.value.emr_id,
      firstName: firstName,
      gender: 'other',
      height_inches: 50,
      lastName: lastName,

      notes: this.patientForm.value.notes,
      pregnancies: 0,
      search_terms: [
        firstName.toLowerCase(),
        lastName.toLowerCase(),
        userEmail.toLowerCase(),
        this.dataService.getFirstName(this.patientForm.value.provider_id).toLowerCase(),
        this.dataService.getLastName(this.patientForm.value.provider_id).toLowerCase(),
      ],
      ssn_id: this.patientForm.value.ssn_id,
      weight_lbs: 100,
      providerGenerated: true,
    };
    return patient;
  }

  private async canSavePatientAfterBillingRestriction(): Promise<boolean> {
    const currentUser = this.auth.user;
    const currentClient = (await this.clientService.getClientById(currentUser.client_responsible_id)).data();
    if (currentClient?.billing_approved) {
      return true;
    } else {
      return currentClient?.patients_loaded ? currentClient.patients_loaded < environment.trial_patient_loads : false;
    }
  }

  private async updateClientInfo() {
    const currentUser = this.auth.user;
    const currentClient = (await this.clientService.getClientById(currentUser.client_responsible_id)).data();
    this.clientService.updateClient(currentUser.client_responsible_id, { patients_loaded: currentClient.patients_loaded + 1 });
  }

  private showErrorNotification(): void {
    this.snack.genericSnackBar('Error: Exceed the number of patients. Contact administrator.', ['error-snackbar'], 5000);
    this.isLoading = false;
    this.dialogRef.close();
  }

  private savePatient(patient: Patient): void {
    this.patientService.createPatient(patient).subscribe(
      (val) => {
        console.info('POST call successful value returned in body', val);
        this.afAuth.auth.sendPasswordResetEmail(patient.email);
        this.snack.genericSnackBar(val.message, ['success-snackbar']);
        const id = val?.user_id;
        this.dialogRef.close();
        this.isLoading = false;
        this.updateClientInfo();
        this.router.navigateByUrl(`clinical/home/${id}`);
      },
      (error) => {
        console.info('POST really screwed something up', error);
        this.dialogRef.close();
        this.isLoading = false;
      },
      () => {
        console.info('The POST observable is now completed.');
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
