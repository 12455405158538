<form class="search-patient">
  <mat-form-field class="search-patient-field" floatLabel="never">
    <input
      class="search-patient-input"
      type="text"
      placeholder="Search Patient"
      aria-label="Search patient"
      matInput
      [formControl]="searchInput"
      [matAutocomplete]="auto"
    />
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="getOptionText">
      <mat-option *ngFor="let patient of filteredOptions$ | async" [value]="patient" (click)="navigatePatientDetail(patient.user_id)">
        <img class="search-patient-img" aria-hidden [src]="patient.profileImageURL" height="25" />
        <span>{{ patient.firstName }} {{ patient.lastName }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
