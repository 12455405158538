import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SecureEmail, SecureEmailForm } from 'src/app/models/secure-email.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { SecureEmailService } from 'src/app/services/communications/secure-email.service';
import { SnackService } from 'src/app/services/snack.service';
import { emailWelbyValidator } from '../../validators/email-welby.validator';
import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-email-patient-dialog',
  templateUrl: './email-patient-dialog.component.html',
  styleUrls: ['./email-patient-dialog.component.scss'],
})
export class EmailPatientDialogComponent implements OnInit {
  @ViewChild('inputFile') inputFile: ElementRef;
  emailForm: FormGroup;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  selectable = true;
  removable = true;
  addOnBlur = true;
  isEmailSecure = false;
  loading = false;
  currentUser;
  welbyEmailRegex = /^[\w-\.]+@(getwelby+\.)+[\w-]{2,4}$/;
  attachments = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<EmailPatientDialogComponent>,
    private fb: FormBuilder,
    private secureEmailService: SecureEmailService,
    private snackService: SnackService,
    private authService: NewAuthService,
    private utilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.user;
    this.initializeForm();
  }

  getValidWelbyEmail() {
    const email: string = this.currentUser?.email;
    return this.welbyEmailRegex.test(email) ? email : environment.defaultWelbyEmail;
  }

  initializeForm(): void {
    this.emailForm = this.fb.group({
      subject: ['', Validators.required],
      recipients: [this.getInitialRecipients(), emailWelbyValidator(false)],
      cc: [''],
      from: [this.getValidWelbyEmail()],
      message: ['', Validators.required],
      isEmailSecure: [this.isEmailSecure, Validators.required],
      forceSecureNotification: [true, Validators.required],
    });
  }

  sendEmail(): void {
    if (this.emailForm.get(SecureEmailForm.IS_EMAIL_SECURE).value) {
      let emailInformation = this.transformFormIntoEmailbody();
      this.loading = true;
      this.sendSecureEmail(emailInformation);
    } else {
      this.sendNoSecureEmail();
    }
  }

  toggleSendSecure(): void {
    this.isEmailSecure = !this.isEmailSecure;
    this.isEmailSecure
      ? this.emailForm.get(SecureEmailForm.FROM).setValidators([Validators.required, emailWelbyValidator()])
      : this.emailForm.get(SecureEmailForm.FROM).clearValidators();
    this.emailForm.get(SecureEmailForm.FROM).updateValueAndValidity();
    this.emailForm.get(SecureEmailForm.RECIPIENTS).setValue(this.getInitialRecipients());
  }

  toggleforceSecureNotification(): void {
    this.emailForm
      .get(SecureEmailForm.FORCE_SECURE_NOTIFICATION)
      .setValue(!this.emailForm.get(SecureEmailForm.FORCE_SECURE_NOTIFICATION).value);
  }

  close(): void {
    this.dialogRef.close();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.emailForm.get(SecureEmailForm.RECIPIENTS).setValue([...this.emailForm.get(SecureEmailForm.RECIPIENTS).value, value]);
    }
    if (event.input) {
      event.input.value = '';
    }
  }

  remove(recipient: string): void {
    const index = this.emailForm.get(SecureEmailForm.RECIPIENTS).value.indexOf(recipient);
    const recipients: string[] = [...this.emailForm.get(SecureEmailForm.RECIPIENTS).value];
    if (index >= 0) {
      recipients.splice(index, 1);
      this.emailForm.get(SecureEmailForm.RECIPIENTS).setValue(recipients);
    }
  }

  openSelectFileDialog(event) {
    event.preventDefault();
    this.inputFile.nativeElement.click();
  }

  async onDocumentFileSelected(event) {
    event.preventDefault();
    let files = await Promise.all(
      Array.from(event.target.files).map(async (file: File) => {
        const fileBase64 = await this.utilsService.getBase64FromFile(file);
        return { fileName: file.name, contentType: file.type, content: fileBase64 };
      })
    );
    this.attachments = files ? [...files, ...this.attachments] : [];
  }

  private sendSecureEmail(emailInformation: SecureEmail): void {
    this.secureEmailService.sendEmail(emailInformation).subscribe(
      (resp) => {
        this.snackService.genericSnackBar('Email has been sent successfully', ['success-snackbar']);
        this.loading = false;
        this.dialogRef.close();
      },
      (error) => {
        this.snackService.genericSnackBar(`Internal error. Description: ${error.message}`, ['error-snackbar']);
        this.loading = false;
      }
    );
  }

  private sendNoSecureEmail(): void {
    const emails = (this.emailForm.get(SecureEmailForm.RECIPIENTS).value as []).join(';');
    const link = `mailto:${emails}?subject=${this.emailForm.get('subject').value}&body=${
      this.emailForm.get(SecureEmailForm.MESSAGE).value
    }`;
    window.location.href = link;
    this.close();
  }

  private getInitialRecipients(): string[] | string {
    const recipients = [];
    if (!this.welbyEmailRegex.test(this.currentUser.email)) {
      if (this.currentUser?.email && this.isEmailSecure) {
        recipients.push(this.currentUser.email);
      }
    }
    if (this.data?.email) {
      recipients.push(this.data.email);
    }
    return recipients.length > 0 ? recipients : '';
  }

  private transformFormIntoEmailbody(): SecureEmail {
    let emailInformation: SecureEmail = {
      recipients: this.emailForm.get(SecureEmailForm.RECIPIENTS).value,
      headers: {
        subject: this.emailForm.get(SecureEmailForm.SUBJECT).value,
        from: this.emailForm.get(SecureEmailForm.FROM).value,
      },
      content: {
        'text/plain': this.emailForm.get(SecureEmailForm.MESSAGE).value,
      },
      forceSecureNotification: this.emailForm.get(SecureEmailForm.FORCE_SECURE_NOTIFICATION).value,
    };
    if (this.attachments && this.attachments.length > 0) {
      emailInformation['attachments'] = this.attachments;
    }

    return emailInformation;
  }
}
