import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  VitalFlagLabelModel,
  VitalFlagOffsetTypes,
  VitalFlagOffsetTypesLabels,
  VitalFlagOperators,
  VitalFlagOperatorsLabels,
} from '../../models/vital_flags.model';

@Component({
  selector: 'app-target',
  templateUrl: './target.component.html',
  styleUrls: ['./target.component.scss'],
})
export class TargetComponent implements OnInit {
  @Input()
  targetForm: FormGroup;
  @Input()
  isCustomTarget: boolean = false;
  operators: VitalFlagLabelModel[] = Object.keys(VitalFlagOperatorsLabels).map((key) => ({
    label: VitalFlagOperatorsLabels[key],
    value: VitalFlagOperators[key],
  }));
  types: VitalFlagLabelModel[] = Object.keys(VitalFlagOffsetTypes).map((key) => ({
    label: VitalFlagOffsetTypesLabels[key],
    value: VitalFlagOffsetTypes[key],
  }));

  constructor() {}

  ngOnInit(): void {}
}
