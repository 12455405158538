import { Directive, HostListener } from '@angular/core';
import { UtilsService } from 'src/app/services/utils.service';

@Directive({
  selector: '[phoneMask]',
})
export class PhoneFormaterDirective {
  constructor(private utilService: UtilsService) {}

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmedValue = input.value.replace(/\s+/g, '');

    if (trimmedValue.length > 12) {
      trimmedValue = trimmedValue.substring(0, 12);
    }
    trimmedValue = trimmedValue.replace(/-/g, '');

    input.value = this.utilService.getPhoneNumberFormated(trimmedValue);
  }
}
