import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { ClinicalResolution } from 'src/app/models/clinical.model';
import { PatientContact } from 'src/app/models/patient.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { TwilioService } from 'src/app/services/communications/twilio.service';
import { DataService } from 'src/app/services/data.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';

@Component({
  selector: 'app-call-patient-dialog',
  templateUrl: './call-patient-dialog.component.html',
  styleUrls: ['./call-patient-dialog.component.scss'],
})
export class CallPatientDialogComponent implements OnInit, AfterViewInit {
  patientContacts$: Observable<PatientContact[]>;
  patient_id: string;
  callForm: FormGroup;
  phoneTypes: any[] = [];
  callInProgress: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<CallPatientDialogComponent>,
    private route: ActivatedRoute,
    private fsService: FirestoreService,
    private fb: FormBuilder,
    private dataService: DataService,
    private patientService: PatientService,
    private authService: NewAuthService,
    private snackBarService: SnackService,
    public twilio: TwilioService
  ) {
    this.initializeForm();
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.phoneTypes = Object.values(this.dataService.phoneTypes);
    this.patient_id = this.data.id;
    this.patientContacts$ = this.fsService.colWithIds$<PatientContact[]>(`users/${this.patient_id}/my_contacts`);
  }

  initializeForm(): void {
    this.callForm = this.fb.group({
      contactNumber: ['', Validators.required],
      notes: ['', Validators.required],
      follow_up: [''],
    });
  }

  getIconFromType(contactType: string): string {
    let phoneType;
    if (this.phoneTypes && contactType) {
      phoneType = this.phoneTypes.find((phoneType) => phoneType.value === contactType);
    }
    return phoneType ? phoneType.icon : 'phone';
  }

  doCall(): void {
    if (
      this.callForm.get('contactNumber').value &&
      this.authService.user &&
      this.authService.user.phone &&
      this.authService.user.twilio_line
    ) {
      this.callInProgress = true;
      this.twilio
        .callPatient(this.callForm.get('contactNumber').value.contact_data, this.authService.user)
        .then((resp) => {
          this.snackBarService.genericSnackBar('Calling in progress ...', ['success-snackbar']);
        })
        .catch((error) => {
          this.snackBarService.genericSnackBar(`Internal Error: ${error}`, ['error-snackbar'], 5000);
          console.error(error);
        })
        .finally(() => (this.callInProgress = false));
    } else {
      this.snackBarService.genericSnackBar('Please verify sender and patient contact number', ['error-snackbar'], 5000);
    }
  }
  saveNotes(): void {
    let resolution: ClinicalResolution = {
      assessor: this.authService.afAuth.auth.currentUser.uid,
      content: this.callForm.get('notes').value,
      follow_up: this.callForm.get('follow_up').value,
      date: firebase.firestore.Timestamp.fromDate(new Date()),
      associations: {
        uid: this.patientService?.currentPatientServiceID,
        client_id: this.patientService?.currentPatientService?.client_responsible_id,
      },
    };
    this.fsService.add(`clinical_assessments`, resolution).then((resp) => {
      this.close();
      this.snackBarService.genericSnackBar('Notes added', ['success-snackbar']);
    });
  }
  close(): void {
    this.dialogRef.close();
  }
}
