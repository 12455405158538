import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { VitalFlagTypes } from '../../models/vital_flags.model';

@Component({
  selector: 'app-alert-type',
  templateUrl: './alert-type.component.html',
  styleUrls: ['./alert-type.component.scss'],
})
export class AlertTypeComponent implements OnInit {
  @Input()
  alertTypeForm: FormGroup;
  @Input()
  isCustomTarget: boolean = false;
  types = Object.values(VitalFlagTypes).map((value) => `${value.charAt(0).toLocaleUpperCase()}${value.substring(1).toLowerCase()}`);

  constructor() {}

  ngOnInit(): void {}
}
