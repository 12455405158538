
import { AngularFirestore } from '@angular/fire/firestore';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';

interface Type {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-careplan-dialog',
  template: `
  <form [formGroup]="planForm" >
    <h1 mat-dialog-title>{{ data.isNew ? "Add New Care Plan Item" : "Edit Plan" }}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:72%;">
        <mat-label>Care plan title</mat-label>
        <input placeholder="Enter a title" matInput formControlName="title" [(ngModel)]="data.planItem.title" required/>
        <mat-error>A title is required to justify the device request</mat-error>
      </mat-form-field>

      <mat-checkbox style="width: 28%;" [(ngModel)]="data.planItem.on_care_plan" formControlName="on_care_plan">
        Add To Care Plan
      </mat-checkbox>

      <mat-form-field style="width:100%;">
        <mat-label>Care plan description</mat-label>
        <input placeholder="Enter a description" matInput formControlName="description" [(ngModel)]="data.planItem.description" required/>
        <mat-error>A title is required to justify the device request</mat-error>
      </mat-form-field>

      <mat-form-field style="width:100%;">
        <mat-label>Link</mat-label>
        <input placeholder="Enter a title" matInput formControlName="link" [(ngModel)]="data.planItem.link" required/>
        <mat-error>A title is required to justify the device request</mat-error>
      </mat-form-field>

      <mat-form-field style="width:39%; margin-right: 1%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="type" [(ngModel)]="data.planItem.type" required>
          <mat-option *ngFor="let type of carePlanTypes" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width:39%; margin-right: 1%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="status" [(ngModel)]="data.planItem.status" required>
          <mat-option *ngFor="let s of status" [value]="s">
            {{ s }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width: 19%">
        <input matInput formControlName="date_opened" [(ngModel)]="data.planItem.date_opened" [matDatepicker]="picker" placeholder="Date Opened" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>Valid date required</mat-error>
      </mat-form-field>

    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="planForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
  `,
  styles: []
})
export class CareplanDialogComponent implements OnInit {

  planForm: FormGroup;

  carePlanTypes: Type[] = [
    { value: 'problems', viewValue: 'Problem'},
    { value: 'outcomes', viewValue: 'Outcome'},
    { value: 'goals', viewValue: 'Goal' },
    { value: 'interventions', viewValue: 'Interventions'},
    { value: 'education', viewValue: 'Education' },
  ];

  status = ['not started', 'progressing', 'stuck', 'exceeding', 'completed']


  constructor(private dialogRef: MatDialogRef<CareplanDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder, private db: AngularFirestore, private patientService: PatientService ) { }

  ngOnInit(): void {
    this.planForm = this.fb.group ({
      date_opened: ['', [Validators.required]],
      description: ['descrption', Validators.required],
      link: ['link'],
      on_care_plan: ['', []],
      status: ['status', Validators.required],
      title: ['title', Validators.required],
      type: ['type', Validators.required],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onDelete(type: string, id: string) {
    const uid = this.patientService.currentPatientServiceID
    this.db.collection('care_plans').doc(uid).collection(type).doc(id).delete();
    this.onNoClick()
  }


}
