import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PatientFlagCriteria } from 'src/app/models/vitals/vital-flag.model';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PatientService } from 'src/app/services/patient.service';

@Injectable({
  providedIn: 'root',
})
export class VitalFlagService {
  constructor(private fsService: FirestoreService, private patientService: PatientService) {}

  saveVitals(vitalFlag: PatientFlagCriteria): Promise<any> {
    return this.fsService.add(`users/${this.patientService.currentPatientServiceID}/my_vital_flags`, vitalFlag);
  }
}
