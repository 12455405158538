import { AngularFirestore } from '@angular/fire/firestore';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Device } from 'src/app/models/patient.model';
import { DeviceOrderingService } from 'src/app/services/device-ordering/device-ordering.service';
import { DeviceVO, OrderVO } from 'src/app/services/device-ordering/value-objects/order.vo';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { RoleModel } from 'src/app/services/models/role-model.service';

interface Type {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-device-order-dialog',
  templateUrl: './device-order-dialog.component.html',
  styleUrls: ['./device-order-dialog.component.scss']
})
export class DeviceOrderDialogComponent implements OnInit {

  selectedManufacturer = ''
  selectedDevice: DeviceVO = null;

  deviceForm: FormGroup;
  isLoading = false;
  delivery = true;
  orderResponse = '';

  states = ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FL', 'GA', 'HI', 'ID',
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MT', 'ME', 'NH', 'NV', 'NJ', 'NM',
    'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'PA',
    'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

  // tslint:disable-next-line: max-line-length
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DeviceOrderDialogComponent>,
    private db: AngularFirestore,
    private fb: FormBuilder,
    private http: HttpClient,
    public patientService: PatientService,
    public afAuth: AngularFireAuth,
    public deviceOrderingService: DeviceOrderingService,
    public roleModel: RoleModel
  ) { }

  ngOnInit(): void {
    this.deviceForm = this.fb.group({
      notes: ['test', Validators.required],
      manufacturer: ['bodytrace', Validators.required],
      type: ['', Validators.required],
      device_id: [''],
      active: [''],
      ordered: [''],
      delivered: [''],
      shipping_street: [''],
      shipping_city: [''],
      shipping_state: [''],
      shipping_zip: [''],
      shipping_phone: [''],
    })

    this.deviceOrderingService
      .getAllDevices()
      .pipe(take(1))
      .subscribe();
  }

  handleManufacturerSelected() {
    this.deviceOrderingService
      .getDevicesByManufacturer(this.selectedManufacturer)
      .pipe(take(1))
      .subscribe();
  }

  async orderDevice() {

    const orderVO: OrderVO = {
      userId: this.afAuth.auth.currentUser.uid,
      patient: {
        id: this.patientService.currentPatientServiceID,
        firstName: this.patientService.currentPatientService.firstName,
        lastName: this.patientService.currentPatientService.lastName,
        email: this.patientService.currentPatientService.email,
        address: {
          street: this.deviceForm.value.shipping_street,
          city: this.deviceForm.value.shipping_city,
          state: this.deviceForm.value.shipping_state,
          postalCode: this.deviceForm.value.shipping_zip,
          phone: this.deviceForm.value.shipping_phone,
          country: 'US'
        }
      },
      manufacturerId: this.selectedManufacturer,
      orderNotes: this.deviceForm.value.notes,
      device: this.selectedDevice,
      updatedAt: Date.now(),
      createdAt: Date.now()
    }

    // load a NEW DEVICE to the user

    this.deviceOrderingService.loadDeviceToUserAccount(true, orderVO, this.deviceForm.value.device_id)

    // THEN PLACE THE ORDER
    this.deviceOrderingService.orderDevice(orderVO)
      .pipe(take(1))
      .subscribe(
        (response: OrderVO) => {
          this.orderResponse = 'Your order has been placed.';
          // ADDING THE CODE TO INSERT THE MAPPING here
          console.log('Order response', orderVO);

        }
      )

  }

  // ---------------------------------------


  onNoClick(): void {
    this.dialogRef.close();
  }

  // tslint:disable-next-line: variable-name
  // activateDevice(mfg: string, device_type: string, type: string, id?: string) {
  //   console.log(mfg, type, id, 'device test');
  //   this.isLoading = true;

  //   const deviceRecordID = `${mfg}_${device_type}`;
  //   const userDeviceMapID = `${this.patientService.currentPatientServiceID}_${id}`

  //   const userDeviceMap = {
  //     device_id: id,
  //     device_mfg: deviceRecordID,
  //     device_ref_id: deviceRecordID,
  //     user_id: this.patientService.currentPatientServiceID,
  //   }

  //   const addressObject = {
  //     street: this.deviceForm.value.shipping_street,
  //     city: this.deviceForm.value.shipping_city,
  //     state: this.deviceForm.value.shipping_state,
  //     postalCode: this.deviceForm.value.shipping_zip,
  //     country: 'US'
  //   }

  //   const deviceData: Device = {
  //     ordered: this.deviceForm.value.ordered,
  //     delivered: this.deviceForm.value.delivered,
  //     active: this.deviceForm.value.active,
  //     device_manufacturer: this.deviceForm.value.manufacturer,
  //     device_model: this.deviceForm.value.type,
  //     device_id: this.deviceForm.value.device_id,
  //     last_update: new Date(),
  //     order_notes: this.deviceForm.value.notes
  //   }

  //   const body = {
  //     uid: this.patientService.currentPatientServiceID,
  //     mfg,
  //     device_type,
  //     activation_type: type,
  //     device_id: id,
  //     address: addressObject,
  //     phone: this.deviceForm.value.shipping_phone
  //   }


  //   const url = `${environment.firebaseURL}/api/device_management`
  //   this.http.post(url, body).subscribe(
  //     (val) => {
  //       console.log('POST Successful. Response:', val);
  //       if (type === 'order') {
  //         deviceData.ordered = true;
  //         console.log('test order response');
  //         // tslint:disable-next-line: no-string-literal
  //         this.db.collection('device_orders').doc(val['id']).set(val)
  //         this.patientService.addDevice(deviceData, this.patientService.currentPatientServiceID, true)
  //         this.onNoClick()
  //       }

  //     },
  //     response => {
  //       console.log('Some response returned from endpoint, but not as expected', response);
  //       this.isLoading = false
  //       this.orderResponse = response.statusText;
  //     },
  //     () => {

  //       if (type === 'activate') {
  //         deviceData.active = true;
  //         this.db.collection('device_user_mapping').doc(userDeviceMapID).set(userDeviceMap, { merge: true });
  //         this.db.collection('users').doc(body.uid).collection('my_devices').doc(deviceRecordID).set(deviceData, { merge: true })
  //       }
  //       if (type === 'deactivate') {
  //         // tslint:disable-next-line: max-line-length
  //         deviceData.active = false; this.db.collection('users').doc(body.uid).collection('my_devices').doc(deviceRecordID).set(deviceData, { merge: true })
  //       }

  //       this.isLoading = false;
  //       this.onNoClick()
  //       console.log('The POST observable is now completed.');
  //     });

  // }

  // tslint:disable-next-line: variable-name
  deleteDevice(uid: string, device_id: string, mfg: string, type: string) {
    const mappedID = `${uid}_${device_id}`;
    const deviceRecordID = `${mfg}_${type}`;
    this.db.collection('device_user_mapping').doc(mappedID).delete();  // delete the mapping
    // tslint:disable-next-line: max-line-length
    this.db.collection('users').doc(uid).collection('my_devices').doc(deviceRecordID).delete();  // then delete the device from the patient record
    this.dialogRef.close();
    console.log('Device has been deleted', mappedID);
  }



}

