import { LocalStorageService } from './local-storage.service';
import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

export const WELBY_TOKEN = 'welby-token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  private jwtToken: string | null = null;
  private decodedToken: { [key: string]: string } | null = null;

  constructor(
    private localStorage: LocalStorageService,
    private http: HttpClient
  ) {
    const token = this.localStorage.get(WELBY_TOKEN);
    if (token) {
      this.jwtToken = token;
    }
  }

  requestNewToken(username, pwHash): Promise<{ access_token: string }> {
    const endpoint = environment.welbyEndpoint + '/api/v1/auth/authorize';
    const body = { username, password: pwHash };
    return this.http.post<{ access_token: string }>(endpoint, body)
      .pipe(
        tap(result => console.log('token stored')),
        tap(result => this.setToken(result.access_token)),
        retry(5)
      ).toPromise();
  }

  setToken(token: string): void {
    if (token) {
      this.jwtToken = token;
      this.localStorage.set(WELBY_TOKEN, token);
    }
  }

  getToken(): string | null {
    return this.jwtToken;
  }

  destroyToken(): void {
    this.jwtToken = null;
    this.localStorage.remove(WELBY_TOKEN);
  }

  decodeToken(): void {
    if (this.jwtToken) {
      this.decodedToken = jwt_decode(this.jwtToken);
    }
  }

  getDecodeToken(): string {
    return jwt_decode(this.jwtToken as string);
  }

  getExpiryTime(): string | null {
    this.decodeToken();
    return this.decodedToken ? this.decodedToken.exp : null;
  }

  tokenIsExpired(): boolean {
    if (!this.jwtToken) {
      return true;
    }
    const expiryTime: number = parseInt(this.getExpiryTime() as string, 10);
    if (expiryTime) {
      return ((1000 * expiryTime) - (new Date()).getTime()) < 5000;
    } else {
      return false;
    }
  }
}
