<form [formGroup]="measureForm">
  <h2>Please select measure type</h2>
  <div class="form-row">
    <mat-form-field>
      <mat-label>Measure type</mat-label>
      <mat-select formControlName="alert_category" required>
        <mat-option *ngFor="let category of categories" [value]="category">
          {{ category }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div class="form-radio-group-container" *ngIf="measureForm.get('alert_category').value === 'Blood Pressure'">
      <label>Select secondary</label>
      <mat-radio-group class="form-radio-group" aria-label="Select an option" formControlName="secondary">
        <mat-radio-button *ngFor="let bpFlag of vitalFlagBPSecondary" [value]="bpFlag">{{ bpFlag }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</form>
