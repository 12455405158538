<p>Generate a new patient care plan</p>

<div class="main-body" fxLayout="column" fxLayoutGap="10px">
  <div class="input-row" fxFill fxLayout="row">
    <!-- just to not set autocomplete when form is opened -->
    <mat-form-field style="width: 0%;">
      <input matInput [(ngModel)]="dx">
    </mat-form-field>

    <mat-form-field appearance="fill" style="width: 100%;">
      <mat-label>Select primary diagnoses</mat-label>
      <input type="text"
              placeholder="Diagnosis"
              aria-label="Search for a diagnosis to develop care plan template"
              matInput
              [formControl]="dx"
              [matAutocomplete]="auto"
              required
            >
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>


    <button mat-icon-button color="accent" matTooltip="Add plan item" (click)="toggleTable()">
      <mat-icon>search</mat-icon>
    </button>
  </div>

  <section class="tab-body" *ngIf="this.tableIsVisible">

    <!-- <div class="chips">
      <mat-form-field class="chip-list" appearance="fill" fxFill>
        <mat-chip-list #chipList aria-label="Patient profile">
          <mat-chip *ngFor="let tag of tags" [selectable]="selectable"
                   [removable]="removable" (removed)="remove(tag)" [color]="tag.color" selected>
            {{tag.name}}
            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
          </mat-chip>
          <input placeholder="Add add'l dx..."
                 [matChipInputFor]="chipList"
                 [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                 [matChipInputAddOnBlur]="addOnBlur"
                 (matChipInputTokenEnd)="add($event)">
        </mat-chip-list>
      </mat-form-field>
    </div> -->

    <mat-tab-group (selectedTabChange)="applyFilter($event)">
      <mat-tab *ngFor="let c of categories; let index = index" [label]="c"  class="tab-title">

        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox (change)="$event ? masterToggle() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="selection.hasValue() && !isAllSelected()">
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)">
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Position Column -->
          <!-- <ng-container matColumnDef="position">
            <th mat-header-cell *matHeaderCellDef> No. </th>
            <td mat-cell *matCellDef="let element"> {{element.position}} </td>
          </ng-container> -->

          <!-- Name Column -->
          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let item"> {{item.title}} </td>
          </ng-container>

          <!-- Symbol Column -->
          <ng-container matColumnDef="description">
            <th mat-header-cell *matHeaderCellDef> Description </th>
            <td mat-cell *matCellDef="let item"> {{item.description}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"
              (click)="selection.toggle(row)">
          </tr>
        </table>


      </mat-tab>
    </mat-tab-group>

    <div dir="rtl" style="margin-top: 20px;">
      <button mat-raised-button color="accent" (click)="createCarePlan()">Add/Replace Care Plan</button>
      <button mat-button  (click)="cancel()">Cancel</button>
    </div>
  </section>

</div>
