import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
  selector: 'app-careplan-profile-dialog',
  template: `
  <h1 mat-dialog-title>Patient Profile</h1>
  <form [formGroup]="profileForm">
    <div mat-dialog-content>

      <div class="mat-row" formArrayName="questions">
        <div *ngFor="let questionControl of controls; let i = index"
          fxLayout="row"
          fxLayoutAlign="start start"
          fxLayoutGap="10px" fxFill
          [formGroupName]="i"
        >

          <mat-form-field fxFlex="3">
            <input type="text" class="form-control" matInput formControlName="tier" [(ngModel)]="data.profile[i].tier" />
          </mat-form-field>
          <mat-form-field fxFlex="12">
            <input type="text" class="form-control" matInput formControlName="category" [(ngModel)]="data.profile[i].category" />
          </mat-form-field>
          <mat-form-field fxFlex="60">
            <input type="text" class="form-control" matInput readonly formControlName="question" [(ngModel)]="data.profile[i].question" />
          </mat-form-field>
          <mat-form-field fxFlex="25">
          <mat-label>Response</mat-label>
            <mat-select formControlName="response" [(ngModel)]="data.profile[i].response">
              <mat-option *ngFor="let response of this.data.profile[i].possible_responses" [value]="this.data.profile[i].possible_responses.indexOf(response)">
                {{ response }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>
    </div>


    <div mat-dialog-actions>
      <button mat-button type="button" (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>

  </form>
`,
styles: []
})
export class CareplanProfileDialogComponent implements OnInit {


constructor(public dialogRef: MatDialogRef<CareplanProfileDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, ) { }

  profileForm: FormGroup;
  profileQuestions = this.data.profile;

  ngOnInit(): void {
    this.onInitForm()
  }


  private onInitForm(): void {
    console.log('test data', this.data);
    const questionList = new FormArray([]);

    this.profileForm = new FormGroup({
      questions: questionList,
    })

    if (this.profileQuestions.length > 0) {
      this.profileQuestions.forEach(q => {

        questionList.push(
          new FormGroup({
            tier: new FormControl({value: q.tier, disabled: true}),
            category: new FormControl({value: q.category, disabled: true}),
            question: new FormControl({value: q.question}),
            response: new FormControl(q.uid, Validators.required),
          })
        )
      });
    }
  }

  get controls(): any { // a getter!
    return (<FormArray>this.profileForm.get('questions')).controls;
  }


  onNoClick(): void {
    this.dialogRef.close();
  }


  onHandleAddress(type: string, index?: number) {
    // const userRef = this.db.collection('users').doc(this.patientService.currentPatientServiceID).collection('my_addresses')
    // const arrayRecord = this.profileForm.value['addresses'][index];

    // switch (type) {
    //   case 'add':
    //     const newAddress: PatientAddress = {
    //       address: this.profileForm.value['newAddLineOne'],
    //       city: this.profileForm.value['newCity'],
    //       state: this.profileForm.value['newState'],
    //       zip: this.profileForm.value['newZip'],

    //     }
    //     console.log('new', newAddress);
    //     console.log(this.profileForm);
    //     userRef.add(newAddress);
    //     break;
    //   case 'edit':
    //     const updatedContact: PatientAddress = {
    //       address: arrayRecord.street,
    //       city: arrayRecord.city,
    //       state: arrayRecord.state,
    //       zip: arrayRecord.zip,
    //     }
    //     console.log('recoprd', arrayRecord);
    //     userRef.doc(arrayRecord.id).set(updatedContact, {merge: true})
    //     break;
    //   case 'delete':
    //     userRef.doc(arrayRecord.id).delete();
    //     break;
    //   default:
    //     break;
    // }

  }

}
