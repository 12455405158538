import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Patient, PatientAddress, PatientContact } from '../models/patient.model';
import { FirestoreService } from './firestore.service';
import { CareTeamMember, ClinicalTime } from '../models/clinical.model';

import { Diagnosis, Medication } from '../models/fhir-models';



@Injectable({
  providedIn: 'root'
})
export class PatientDetailService {

  currentPatient: Patient;
  currentPatientUID: string;
  currentPatient$: Observable<Patient>;

  addresses$: Observable<PatientAddress[]>;
  contacts$: Observable<PatientContact[]>;
  careTeam$: Observable<CareTeamMember[]>;
  clinicalTimes$: Observable<ClinicalTime[]>;
  medications$: Observable<Medication[]>;
  diagnoses$: Observable<Diagnosis[]>;

  constructor( private fsService: FirestoreService) { }

  subscribeToPatient(uid: string) {
    //console.log('test service', uid);
    this.currentPatientUID = uid
    this.currentPatient$ = this.fsService.doc$(`users/${uid}`);
    this.currentPatient$.subscribe((patient: Patient) => {
      // console.log('have an active patient', patient);
      this.currentPatient = patient;
    })
  }

  subscribeToPatientDetail(uid: string) {
    // console.log('subscribing to detail');
    this.addresses$ = this.fsService.colWithIds$(`users/${uid}/my_addresses`);
    this.contacts$ = this.fsService.colWithIds$(`users/${uid}/my_contacts`);
    this.careTeam$ = this.fsService.colWithIds$(`users/${uid}/my_care_team`, (ref) => ref.orderBy('last_name', 'asc'));
    this.clinicalTimes$ = this.fsService.colWithIds$(`users/${uid}/clinical_time`);
    this.medications$ = this.fsService.colWithIds$(`users/${uid}/my_fhir_medications`);
    this.diagnoses$ = this.fsService.colWithIds$(`users/${uid}/my_fhir_diagnoses`, (ref) => ref.orderBy('diagnosis', 'desc'));
  }


}

