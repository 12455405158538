<h1 mat-dialog-title>{{data.title}}</h1>
<div mat-dialog-content>
  <p [innerHtml]="data.body"></p>
  <p>{{data.sku}}</p>
  <p>{{data.manufacturer}}</p>
</div>
<div mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close *ngIf="!data.hideCancelBtn">
    {{data.cancelButton ? data.cancelButton : 'No'}}
  </button>
  <button mat-raised-button color="primary" (click)="confirm()">
    {{data.confirmButton ? data.confirmButton : 'Yes'}}
  </button>
</div>