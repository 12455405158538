import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { FirestoreService } from 'src/app/services/firestore.service';
import { PaginationParam, SearchPatientParams } from './patient-list.model';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})
export class PatientListService {
  collectionFSName: string = 'users';
  clientResponsibleIdFSName: string = 'client_responsible_id';
  lastNameFSName: string = 'lastName';
  rolesIsPatientFSQuery: string = 'roles.isPatient';

  constructor(private auth: NewAuthService, private fsService: FirestoreService) {}

  /**
   * Call firebase service to get patient according to search and paginator params
   * @param pageSize limit option in query
   * @param searchParams
   * @param paginationParams
   * @returns Observable
   */
  getPatients(pageSize: number, searchParams?: SearchPatientParams, paginationParams?: any): Observable<any> {
    return this.fsService.colWithIds$(this.collectionFSName, (ref) => {
      let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
      query = ref
        .where(this.clientResponsibleIdFSName, 'in', this.auth.user.client_accounts)
        .where(this.rolesIsPatientFSQuery, '==', true)
        .orderBy(this.lastNameFSName)
        .limit(pageSize);
      query = searchParams ? this.getQueryFromSearchParams(query, searchParams) : query;
      query = paginationParams ? this.getQueryFromPaginatorParams(query, paginationParams) : query;
      return query;
    });
  }

  /**
   * Build a query from searchParams
   * @param ref - existing query to modify
   * @param searchParams
   * @returns
   */
  getQueryFromSearchParams(
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
    searchParams: SearchPatientParams
  ): firebase.firestore.CollectionReference | firebase.firestore.Query {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    if (searchParams.searchTerms) {
      query = query.where('search_terms', 'array-contains', searchParams.searchTerms[0]);
    }
    if (searchParams.tags) {
      query = query.where('user_tags', 'array-contains', searchParams.tags[0]);
    }
    if (searchParams.active_rpm !== undefined) {
      query = query.where('active_rpm', '==', searchParams.active_rpm);
    }
    return query;
  }

  /**
   * Build a query from paginator params
   * @param ref - existing query to modify
   * @param paginatorParams
   * @returns
   */
  getQueryFromPaginatorParams(
    ref: firebase.firestore.CollectionReference | firebase.firestore.Query,
    paginatorParams: PaginationParam
  ): firebase.firestore.CollectionReference | firebase.firestore.Query {
    let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
    if (paginatorParams && paginatorParams.lastResolution && paginatorParams.isNextClicked) {
      query = query.startAfter(paginatorParams.lastResolution);
    }
    if (paginatorParams && paginatorParams.firstResolution && paginatorParams.isPrevClicked) {
      query = query.startAt(paginatorParams.firstResolution);
    }
    if (paginatorParams && paginatorParams.lastResolution && paginatorParams.isPrevClicked) {
      query = query.endAt(paginatorParams.lastResolution);
    }

    return query;
  }
}
