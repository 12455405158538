<ng-container [ngSwitch]="measure_type">
  <ng-template [ngSwitchCase]="vitalCategory.BLOOD_PRESSURE">
    <ng-container *ngIf="measurement.value!!; else standardDual">
      {{ measurement.value?.sbp | number: '1.0-0' }} / {{ measurement.value?.dbp | number: '1.0-0' }}
      {{ measurement.units }}
    </ng-container>
    <ng-template #standardDual>
      {{ measurement.value1 | number: '1.0-0' }} / {{ measurement.value2 | number: '1.0-0' }}
      {{ measurement.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.BLOOD_GLUCOSE">
    <ng-container *ngIf="measurement.value!!; else standardSingle">
      {{ measurement.value?.mgdl | number: '1.0-0' }}
      {{ measurement.units }}
    </ng-container>
    <ng-template #standardSingle>
      {{ measurement?.value1 | number: '1.0-0' }}
      {{ measurement.units }}
    </ng-template>
  </ng-template>
  <ng-template [ngSwitchCase]="vitalCategory.WEIGHT">
    <ng-container *ngIf="measurement.value!!; else standardWeight"> {{ measurement.value.lbs | number: '1.0-0' }} LBS </ng-container>
    <ng-template #standardWeight>
      {{ measurement?.value1 | number: '1.0-0' }}
      {{ measurement.units }}
    </ng-template>
  </ng-template>
  <ng-template ngSwitchDefault>
    {{ measurement?.value1 | number: '1.0-0' }}
    {{ measurement.units }}
  </ng-template>
</ng-container>
