import { AngularFirestore } from '@angular/fire/firestore';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatientService } from 'src/app/services/patient.service';

interface Type {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-vital-flag-dialog',
  templateUrl: './vital-flag-dialog.component.html',
  styleUrls: ['./vital-flag-dialog.component.scss']
})


export class VitalFlagDialogComponent implements OnInit {

  flagForm: FormGroup;

  types = ['positive', 'negative', 'critical'];
  categories = ['Blood Pressure', 'Blood Glucose', 'Pulse', 'Pulse Ox', 'Weight'];
  comparitors = ['measures', 'days', 'fixed'];
  comparitor_types = ['percent', 'number'];
  offset_types = ['+', '-'];
  operators = ['<', '==', '>', '<=', '>='];

  constructor(private dialogRef: MatDialogRef<VitalFlagDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private db: AngularFirestore,
              private fb: FormBuilder, private http: HttpClient, public patientService: PatientService) { }

  ngOnInit(): void {
    this.flagForm = this.fb.group ({
      active: ['', Validators.required],
      alert_category: ['', Validators.required],
      alert_patient: ['', Validators.required],
      alert_provider: ['', Validators.required],
      alert_type: ['', Validators.required],
      comparitor: ['', Validators.required],
      comparitor_type: ['', Validators.required],
      count: ['', Validators.required],
      offset_amount: ['', Validators.required],
      offset_type: ['', Validators.required],
      operator: ['', Validators.required],
      secondary: ['', Validators.required],
    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
