import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinicalTime } from 'src/app/models/clinical.model';
import { Patient } from 'src/app/models/patient.model';
import { PatientService } from 'src/app/services/patient.service';
import { TimeTrackingState } from './time-tracking-states';

@Component({
  selector: 'app-time-tracking-modal',
  templateUrl: './time-tracking-modal.component.html',
  styleUrls: ['./time-tracking-modal.component.scss'],
})
export class TimeTrackingModalComponent implements OnInit {
  showSaveForm: boolean = false;
  saveTimeData;
  one_minute_in_seconds: number = 60;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      body: string;
      title: string;
      patient: Patient;
      timeSpent: number;
      discardTimeButton?: string;
      saveTimeButton?: string;
      keepTimeButton?: string;
    },
    public dialogRef: MatDialogRef<TimeTrackingModalComponent>
  ) {}

  ngOnInit(): void {}

  openSaveTimeForm(): void {
    this.showSaveForm = true;
    let newTime = {
      category: 'rpms',
      minutes: 0,
      seconds: 0,
      timeStamp: new Date(),
      user_id: this.data.patient.user_id,
      excluded: false,
    } as ClinicalTime;
    newTime.minutes =
      this.data.timeSpent > this.one_minute_in_seconds
        ? (this.data.timeSpent - (this.data.timeSpent % this.one_minute_in_seconds)) / this.one_minute_in_seconds
        : 0;
    newTime.seconds = this.data.timeSpent % this.one_minute_in_seconds;
    this.saveTimeData = {
      time: {
        minutes: newTime.minutes,
        mins: newTime.minutes,
        secs: newTime['seconds'],
        category: newTime.category,
        timeStamp: newTime.timeStamp,
        user_id: newTime.user_id,
        patient_id: newTime.patient_id,
        excluded: newTime.excluded,
        contact: newTime.contact,
      },
      isNew: true,
    };
  }

  keepTracking(): void {
    this.dialogRef.close(TimeTrackingState.KEEP_TRACKING);
  }

  discardTime(): void {
    this.dialogRef.close(TimeTrackingState.DISCARD);
  }

  closeAfterSaveTime(event: boolean): void {
    event ? this.dialogRef.close(TimeTrackingState.SAVE_TIME) : this.dialogRef.close(TimeTrackingState.NOACTION);
  }
}
