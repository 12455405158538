<form [formGroup]="form" (ngSubmit)="onSubmit()">

  <h1 mat-dialog-title>Create New Client</h1>
  <div mat-dialog-content>

    <mat-form-field style="width:100%">
      <input placeholder="Client Name" matInput formControlName="client_name" [(ngModel)]="data.client.client_name" required/>
    </mat-form-field>

    <mat-form-field style="width:100%">
      <input placeholder="Client NPI" matInput formControlName="client_npi" [(ngModel)]="data.client.client_npi" required/>
    </mat-form-field>

    <mat-form-field style="width:100%">
      <input placeholder="Admin Email" matInput formControlName="admin_email" [(ngModel)]="data.client.admin_email" required/>
    </mat-form-field>

    <mat-form-field style="width:32%; margin-right:1%;">
      <input placeholder="CCM Rate" matInput formControlName="ccm_clinical_rate" [(ngModel)]="data.client.ccm_clinical_rate" required>
    </mat-form-field>

    <mat-form-field style="width:32%; margin-right:1%;">
      <input placeholder="RPM Data Rate" matInput formControlName="software_license_rate" [(ngModel)]="data.client.software_license_rate" required>
    </mat-form-field>

    <mat-form-field style="width:33%">
      <input placeholder="RPM Clinical Rate Address" matInput formControlName="rpm_clinical_rate" [(ngModel)]="data.client.rpm_clinical_rate" required/>
    </mat-form-field>

    <mat-form-field style="width:100%">
      <input placeholder="EMR" matInput formControlName="emr_name" [(ngModel)]="data.client.emr_name" required/>
    </mat-form-field>

  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="form.invalid" [mat-dialog-close]="data" cdkFocusInitial>
      Create New Client
    </button>
  </div>

</form>
