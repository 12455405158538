
<section class="left-panel">

</section>


<section class="right-panel">
  <mat-grid-list cols="6" rowHeight="100px">

    <!-- [ngClass]="{'active-room': this.videoChatService.activeAppointment, 'waiting-room': !this.videoChatService.activeAppointment}" -->
    <mat-grid-tile colspan="4" rowspan="6" [ngClass]="{'active-room': (this.videoChatService.activeAppointment$ | async), 'waiting-room': !(this.videoChatService.activeAppointment$ | async)}">
      <app-participants #participants
                        (leaveRoom)="onLeaveRoom($event)"
                        (participantsChanged)="onParticipantsChanged($event)"
                        [activeRoomName]="!!activeRoom ? activeRoom.name : null">
      </app-participants>
    </mat-grid-tile>
    <mat-grid-tile colspan="2" rowspan="3" style="background-color: darkslategrey;">
      <app-camera #camera style="width: 100%; height: 100%;"></app-camera>
    </mat-grid-tile>

      <mat-grid-tile colspan="2" rowspan="5">
        <div id="appointments">
          <h3>Appointments Available</h3>
          <table mat-table [dataSource]="apptList" class="mat-elevation-z4">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let appt">{{ appt.date.toDate() | date:'short'  }}</td>
            </ng-container>
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let appt">
                <ng-container *ngIf="appt.status.hasProvider && appt.status.hasPatient">Active</ng-container>
                <ng-container *ngIf="(appt.status.hasPatient && !appt.status.hasProvider) || (!appt.status.hasPatient && appt.status.hasProvider)">Waiting</ng-container>
                <ng-container *ngIf="!appt.status.hasPatient && !appt.status.hasProvider">Closed</ng-container>
              </td>
            </ng-container>
            <ng-container matColumnDef="join">
              <th mat-header-cell *matHeaderCellDef></th>
              <!-- <td mat-cell *matCellDef="let appt">{{ appt.type }}</td> -->
              <td mat-cell *matCellDef="let appt" style="text-align: end;">
                <button *ngIf="currentUser.roles.isClient && !appt.status.hasProvider" mat-raised-button color="primary" (click)="subscribeToAppointment(appt.id, true, false)">Join</button>
                <button *ngIf="!currentUser.roles.isClient && !appt.status.hasPatient" mat-raised-button color="primary" (click)="subscribeToAppointment(appt.id, false, true)">{{appt.status.hasProvider ? 'Join WR' : 'Join'}}</button>

                <button *ngIf="appt.status.hasProvider && currentUser.roles.isClient" mat-raised-button color="warn" (click)="updateItem(appt.id, false, false); onLeaveRoom(true)">Leave</button>
                <button *ngIf="appt.status.hasPatient && !currentUser.roles.isClient" mat-raised-button color="warn" (click)="updateItem(appt.id, false, false); onLeaveRoom(true)">Leave</button>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let appt; columns: displayedColumns;"></tr>
          </table>
        </div>
        <!-- <button *ngIf="!activeRoom" mat-raised-button color="primary" (click)="onRoomChanged('7IBemHuwTU53ExF0Rx4G')">Start</button>
        <button *ngIf="!!activeRoom" mat-raised-button color="warn" (click)="onLeaveRoom(true)">End Session</button>
        <br>
        <button *ngIf="!activeRoom" mat-raised-button color="primary" (click)="sendReminderText(patientContact)">Send Text</button> -->
      </mat-grid-tile>

    <mat-grid-tile colspan="1" rowspan="2">
      <app-settings #settings (settingsChanged)="onSettingsChanged($event)"></app-settings>
    </mat-grid-tile>

    <mat-grid-tile colspan="3" rowspan="2">

      <div id="resolution" style="width: 100%; padding: 0px 10px;">
        <form [formGroup]="form" *ngIf="(this.auth.user.roles.isClient) && (this.videoChatService.activeAppointment$ | async) as appt" style="width: 100%;">
          <mat-form-field appearance="fill" [color]="content.valid && 'accent'" style="width: 100%;">
            <mat-label>Enter your information about this interaction</mat-label>
            <textarea matInput placeholder="Enter your notes" formControlName="content" style="height: 80px" autocomplete="off" type="text" ></textarea>
            <mat-error *ngIf="content.invalid">
              You must enter data
            </mat-error>
          </mat-form-field>
          <mat-error class="server-error">{{ serverMessage }}</mat-error>
          <button mat-stroked-button (click)="loadResolution(false, appt)" [disabled]="form.invalid || loading" style="width: 200px;">Load Resolution</button>
          <button mat-raised-button (click)="loadResolution(true, appt)" [disabled]="form.invalid || loading" style="width: 200px;">Load / Add Task</button>
        </form>
      </div>


    </mat-grid-tile>
  </mat-grid-list>
</section>

