import { MatTableDataSource } from '@angular/material/table';
import { FirestoreService } from 'src/app/services/firestore.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.scss']
})
export class TrainingComponent implements OnInit {

  trainingSessions = new MatTableDataSource();
  displayedColumns = ['title', 'link'];
  safeURL: SafeResourceUrl;

  constructor( public fs: FirestoreService, private _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.getVideoList()
    this.viewContent('https://youtube.com/embed/1jnhShqCqpE');
  }

  getVideoList() {
    this.fs.colWithIds$(`training`).subscribe((sessions) => {
      this.trainingSessions.data = sessions;
    });
  }

  viewContent(link: string, ) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(link);
  }

}
