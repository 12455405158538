import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';

@Component({
  selector: 'app-provider-dialog',
  templateUrl: './provider-dialog.component.html',
  styleUrls: ['./provider-dialog.component.scss']
})
export class ProviderDialogComponent implements OnInit {

  form: FormGroup;
  loading = false;
  serverMessage: string;
  states = ['AZ', 'CA', 'NV'];


  constructor(private fb: FormBuilder, public dialogRef: MatDialogRef<ProviderDialogComponent>,
              public dataModel: InitialDataModel, @Inject(MAT_DIALOG_DATA) public data: any ) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      client_id: ['', [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
    });
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {

  }

}
