import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';
import { PatientService } from 'src/app/services/patient.service';
import { SnackService } from 'src/app/services/snack.service';
import {
  VitalFlagBloodPressureSecondary,
  VitalFlagComparitors,
  VitalFlagOffsetTypes,
  VitalFlagTypes,
} from '../../models/vital_flags.model';
import { VitalFlagService } from '../../services/vital-flag.service';

@Component({
  selector: 'app-vital-flag-fixed-target',
  templateUrl: './vital-flag-fixed-target.component.html',
  styleUrls: ['./vital-flag-fixed-target.component.scss'],
})
export class VitalFlagFixedTargetComponent implements OnInit {
  @Input()
  data: any = { isNew: true };
  @Output()
  closeEmmiter = new EventEmitter<boolean>();

  isLinear = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;

  flagForm: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'right';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  isExtraSmall$: Observable<BreakpointState> = this.breakpointObserver.observe(Breakpoints.XSmall);

  constructor(
    private vitalFlagService: VitalFlagService,
    private fb: FormBuilder,
    public patientService: PatientService,
    private snackBarService: SnackService,
    private breakpointObserver: BreakpointObserver
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    this.setCategoryListener();
  }
  initializeForm() {
    this.firstFormGroup = this.fb.group({
      alert_category: ['', Validators.required],
      secondary: [false],
      priority: ['max'],
      count: [0],
      comparitor: [VitalFlagComparitors.FIXED],
    });
    this.secondFormGroup = this.fb.group({
      offset_amount: ['', Validators.required],
      offset_type: [VitalFlagOffsetTypes.PLUS],
      operator: ['', Validators.required],
    });
    this.thirdFormGroup = this.fb.group({
      alert_type: [VitalFlagTypes.POSITIVE, Validators.required],
      active: [true, Validators.required],
    });
    this.fourthFormGroup = this.fb.group({
      alert_provider: [true, Validators.required],
      alert_patient: [true, Validators.required],
    });
    if (this.data && !this.data.isNew) {
      this.flagForm.patchValue(this.data);
    }
    this.firstFormGroup.get('secondary').value === true
      ? this.firstFormGroup.get('secondary').setValue(VitalFlagBloodPressureSecondary.DIASTOLIC_BP)
      : this.firstFormGroup.get('secondary').setValue(VitalFlagBloodPressureSecondary.SYSTOLIC_BP);
  }

  save(): void {
    this.firstFormGroup.get('secondary').value === VitalFlagBloodPressureSecondary.SYSTOLIC_BP
      ? this.firstFormGroup.get('secondary').setValue(false)
      : this.firstFormGroup.get('secondary').setValue(true);
    const flagData = {
      ...this.firstFormGroup.getRawValue(),
      ...this.secondFormGroup.getRawValue(),
      ...this.thirdFormGroup.getRawValue(),
      ...this.fourthFormGroup.getRawValue(),
    };
    this.vitalFlagService
      .saveVitals(flagData)
      .then((resp) => {
        this.snackBarService.genericSnackBar('New flag created', ['success-snackbar']);
      })
      .catch((error) => this.snackBarService.genericSnackBar('Error creating flaf', ['error-snackbar']));
    this.closeEmmiter.emit(true);
  }

  cancel(): void {
    this.closeEmmiter.emit(true);
  }

  setCategoryListener(): void {
    this.firstFormGroup.get('alert_category').valueChanges.subscribe((value) => {
      if (value === 'Blood Pressure') {
        this.firstFormGroup.get('secondary').setValue('Systolic');
        this.firstFormGroup.get('secondary').setValidators([Validators.required]);
      } else {
        this.firstFormGroup.get('secondary').clearValidators();
        this.firstFormGroup.get('secondary').setValue('');
        this.firstFormGroup.get('secondary').updateValueAndValidity();
      }
    });
  }
}
