import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TimeCategory } from 'src/app/dialogs/clinical-time-dialog.component';
import { ClinicalTime } from 'src/app/models/clinical.model';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { PatientService } from 'src/app/services/patient.service';

@Component({
  selector: 'app-clinical-time-save-form',
  templateUrl: './clinical-time-save-form.component.html',
  styleUrls: ['./clinical-time-save-form.component.scss'],
})
export class ClinicalTimeSaveFormComponent implements OnInit {
  form: FormGroup;
  @Input() data;
  @Output() closeDialog = new EventEmitter<boolean>();

  constructor(private fb: FormBuilder, private patientService: PatientService, private auth: NewAuthService) {}

  categories: TimeCategory[] = [
    { value: 'rpms', viewValue: 'RPM - Support' },
    { value: 'rpmc', viewValue: 'RPM - Physician' },
    { value: 'ccm', viewValue: 'Chronic Care Planning' },
    { value: 'ev', viewValue: 'eConsult' },
    { value: 'video', viewValue: 'Video Consult' },
  ];

  ngOnInit() {
    this.form = this.fb.group({
      mins: ['0', [Validators.required]],
      secs: ['0', [Validators.required]],
      category: ['', []],
      exclude: [false, []],
      contact: [false, []],
    });
  }

  saveTime(): void {
    const loadTime = this.data.time.mins + this.data.time.secs / this.patientService.one_minute_in_seconds;
    let loadDate = new Date();
    const newTime: ClinicalTime = {
      minutes: loadTime,
      category: this.data.time.category,
      timeStamp: loadDate,
      last_update: new Date(),
      user_id: this.auth.user.user_id,
      patient_id: this.patientService.currentPatientServiceID,
      excluded: this.data.time.excluded ?? false,
      contact: this.data.time.contact ?? false,
    };
    this.patientService.handleClinicalTime(newTime, newTime.patient_id, this.data.isNew);
    this.patientService.resetInterval();
    this.closeDialog.emit(true);
  }

  cancel(): void {
    this.closeDialog.emit(false);
  }
}
