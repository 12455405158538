import { SnackService } from './../snack.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { ClientDemoDialogComponent } from './../../dialogs/admin/client-demo-dialog/client-demo-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Client } from 'src/app/models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientServicesService {
  constructor(private dialog: MatDialog, private db: AngularFirestore, private snack: SnackService) {}

  handleClientRecord(): void {
    console.log('adding provider');

    const dialogRef = this.dialog.open(ClientDemoDialogComponent, {
      width: '500px',
      data: {
        client: {
          rpm_clinical_rate: '30',
          ccm_clinical_rate: '30',
          software_license_rate: '40',
        },
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        const rpm = { active: true, rate: +result.client.rpm_clinical_rate };
        const ccm = { active: true, rate: +result.client.ccm_clinical_rate };
        const license = { active: true, rate: +result.client.software_license_rate };
        const emr = { emr_access: false, emr_login_id: 'N/A', emr_name: result.client.emr_name };
        let client_id = '';

        const client = {
          admin_email: result.client.admin_email,
          client_name: result.client.client_name,
          client_npi: result.client.client_npi,
          rpm_clinical: rpm,
          ccm_clinical: ccm,
          software_license: license,
          billing_approved: false,
          patients_loaded: 0,
        };
        await this.db
          .collection('clients')
          .add(client)
          .then(async function (docRef) {
            client_id = docRef.id;
          })
          .catch(function (error) {
            this.snack.patientLoaded(error);
          });
        await this.db.collection('clients').doc(client_id).collection('emr_data').add(emr);
        this.snack.patientLoaded(`${client.client_name} / ${client_id} has been created.`);
      }
    });
  }

  getClientById(client_id: string) {
    return this.db.collection('clients').doc(client_id).get().toPromise();
  }

  updateClient(client_id: string, body: Client): Promise<any> {
    return this.db.collection('clients').doc(client_id).update(body);
  }
}
