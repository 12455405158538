<mat-vertical-stepper [linear]="isLinear" #stepper *ngIf="(isExtraSmall$ | async)?.matches; else horizontalStep">
  <mat-step [stepControl]="firstFormGroup" label="Measure type" matTooltip="Measure type">
    <app-measure-type [measureForm]="firstFormGroup"></app-measure-type>
    <div class="wizard-button-action">
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="secondFormGroup" matTooltip="Mesurement type" label="Type mesurement">
    <app-measure-frequency [measureFrequencyForm]="secondFormGroup"></app-measure-frequency>
    <div class="wizard-button-action">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="thirdFormGroup" label="Target">
    <app-target [targetForm]="thirdFormGroup" [isCustomTarget]="isCustomTarget"></app-target>
    <div class="wizard-button-action">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="fourthFormGroup" label="Alert type">
    <app-alert-type [alertTypeForm]="fourthFormGroup" [isCustomTarget]="isCustomTarget"></app-alert-type>
    <div class="wizard-button-action">
      <button mat-button matStepperPrevious>Back</button>
      <button mat-button matStepperNext>Next</button>
    </div>
  </mat-step>
  <mat-step [stepControl]="fifthFormGroup" label="Notifications">
    <app-vital-flag-notification [notificationForm]="fifthFormGroup"></app-vital-flag-notification>
    <div class="wizard-button-action">
      <button mat-button matStepperPrevious>Back</button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="
          firstFormGroup.invalid || secondFormGroup.invalid || thirdFormGroup.invalid || fourthFormGroup.invalid || fifthFormGroup.invalid
        "
        (click)="save()"
      >
        Save
      </button>
    </div>
  </mat-step>
</mat-vertical-stepper>
<ng-template #horizontalStep>
  <mat-horizontal-stepper [linear]="isLinear">
    <mat-step [stepControl]="firstFormGroup" label="Measure type" matTooltip="Measure type">
      <app-measure-type [measureForm]="firstFormGroup"></app-measure-type>
      <div class="wizard-button-action">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="secondFormGroup" matTooltip="Mesurement type" label="Type mesurement">
      <app-measure-frequency [measureFrequencyForm]="secondFormGroup"></app-measure-frequency>
      <div class="wizard-button-action">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="thirdFormGroup" label="Target">
      <app-target [targetForm]="thirdFormGroup" [isCustomTarget]="isCustomTarget"></app-target>
      <div class="wizard-button-action">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="fourthFormGroup" label="Alert type">
      <app-alert-type [alertTypeForm]="fourthFormGroup" [isCustomTarget]="isCustomTarget"></app-alert-type>
      <div class="wizard-button-action">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="fifthFormGroup" label="Notifications">
      <app-vital-flag-notification [notificationForm]="fifthFormGroup"></app-vital-flag-notification>
      <div class="wizard-button-action">
        <button mat-button matStepperPrevious>Back</button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="
            firstFormGroup.invalid || secondFormGroup.invalid || thirdFormGroup.invalid || fourthFormGroup.invalid || fifthFormGroup.invalid
          "
          (click)="save()"
        >
          Save
        </button>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</ng-template>
