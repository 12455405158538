import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-client-demo-dialog',
  templateUrl: './client-demo-dialog.component.html',
  styleUrls: ['./client-demo-dialog.component.scss'],
})
export class ClientDemoDialogComponent implements OnInit {
  form: FormGroup;
  loading = false;
  serverMessage: string;
  states = ['AZ', 'CA', 'NV'];

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ClientDemoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      admin_email: ['', [Validators.required]],
      client_name: ['', [Validators.required]],
      client_npi: ['', [Validators.required]],
      rpm_clinical_rate: ['', [Validators.required]],
      ccm_clinical_rate: ['', [Validators.required]],
      software_license_rate: ['', [Validators.required]],
      emr_name: ['', [Validators.required]],
      billing_approved: [false],
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  async onSubmit() {}
}
