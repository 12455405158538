import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-are-you-sure-modal',
  templateUrl: './are-you-sure-modal.component.html',
  styleUrls: ['./are-you-sure-modal.component.scss']
})
export class AreYouSureModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { 
      body: string, 
      title: string, 
      sku?: string, 
      manufacturer?: string,
      confirmButton?: string,
      cancelButton?: string
      hideCancelBtn?: boolean
    },
    public dialogRef: MatDialogRef<AreYouSureModalComponent>,
  ) { }

  ngOnInit(): void {
  }

  confirm() {
    this.dialogRef.close(true);
  }

}
