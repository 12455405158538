<form [formGroup]="callForm" (ngSubmit)="doCall()" class="call-form">
  <h2>Call patient</h2>
  <mat-form-field appearance="outline">
    <span [style.display]="select?.value ? 'block' : 'none'" matPrefix style="padding-right: 16px"
      ><mat-icon class="contact-icon">{{ getIconFromType(select.value?.contact_type) }}</mat-icon></span
    >
    <mat-select #select formControlName="contactNumber">
      <mat-option *ngFor="let contact of patientContacts$ | async" [value]="contact">
        {{ contact.contact_data }}
      </mat-option>
    </mat-select>
    <mat-hint>Please select a contact number</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="callInProgress">
    <mat-label>Notes</mat-label>
    <textarea matInput formControlName="notes" rows="10"></textarea>
    <mat-hint>Add notes</mat-hint>
  </mat-form-field>
  <mat-form-field appearance="outline" *ngIf="callInProgress">
    <mat-label>Next step</mat-label>
    <input matInput formControlName="follow_up" />
    <mat-hint>Add next step</mat-hint>
  </mat-form-field>
  <div class="call-form-footer">
    <div class="call-form-status" *ngIf="callInProgress">
      <p>Call in progress</p>
    </div>
    <div class="call-form-buttons">
      <button mat-raised-button color="primary" *ngIf="!callInProgress" [disabled]="callForm.get('contactNumber').invalid" type="submit">
        <mat-icon>phone</mat-icon> Call
      </button>
      <button mat-raised-button color="primary" *ngIf="callInProgress" type="button" (click)="close()">
        <mat-icon>phone</mat-icon> Close
      </button>
      <button
        mat-raised-button
        *ngIf="callInProgress"
        color="accent"
        [disabled]="callForm.get('notes').invalid || callForm.get('follow_up').invalid"
        (click)="saveNotes()"
        type="button"
      >
        Save notes
      </button>
    </div>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="callInProgress"> </mat-progress-bar>
</form>
