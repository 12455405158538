import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor() {}

  /**
   * Get American phone number format
   * @param phone
   * @returns string
   */
  getPhoneNumberFormated(phone: string): string {
    let numbers = [];
    numbers.push(phone.substring(0, 3));
    if (phone.substring(3, 6) !== '') numbers.push(phone.substring(3, 6));
    if (phone.substring(6, 10) != '') numbers.push(phone.substring(6, 10));
    return numbers.join('-').substring(0, 12);
  }

  /**
   * Base64 from file
   * @param file
   * @returns Promise<string>
   */
  getBase64FromFile(file: Blob): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const extractaFile = reader && reader.result ? (reader.result as string).split(',')[1] : '';
        resolve(extractaFile);
      };
      reader.onerror = (error) => reject(error);
    });
  }
}
