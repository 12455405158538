import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaginatorPosition } from './simple-paginator.enum';

@Component({
  selector: 'wellby-simple-paginator',
  templateUrl: './simple-paginator.component.html',
  styleUrls: ['./simple-paginator.component.scss'],
})
export class SimplePaginatorComponent implements OnInit {
  @Output() prevEmit = new EventEmitter<number>();

  @Output() nextEmit = new EventEmitter<number>();

  @Input()
  disable_next: boolean = false;

  @Input()
  disable_prev: boolean = true;

  @Input()
  position: PaginatorPosition = PaginatorPosition.CENTER;

  pagination_clicked_count: number = 1;

  constructor() {}

  ngOnInit(): void {}

  prevPage(): void {
    this.pagination_clicked_count--;
    this.prevEmit.emit(this.pagination_clicked_count);
  }

  nextPage(): void {
    this.pagination_clicked_count++;
    this.nextEmit.emit(this.pagination_clicked_count);
  }
}
