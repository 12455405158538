import { AngularFireFunctions } from '@angular/fire/functions';
import { Patient } from './../../models/patient.model';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TwilioService {

  constructor(private fireFns: AngularFireFunctions) { }

  sendPatientText(receiver: Patient, sender: Patient, text: string) {
    console.log('patient', receiver, sender);

    const to = receiver.sms_contact
    const twilio_line = sender.twilio_line!! ? sender.twilio_line : '+16232634555';
    const callable = this.fireFns.httpsCallable('sendText');
    const authSend = callable({ from: twilio_line, to, message: text });
    return authSend.toPromise();
  }

  callPatient(outboundNumber: string, sender: Patient) {
    const userLine = sender.phone
    const twilio = sender.twilio_line

    console.log('testing the data we are sending', userLine, twilio);

    const callable = this.fireFns.httpsCallable('outboundCall');
    const authSend = callable({ welbyUserLine: userLine, welbyPatientLine: outboundNumber, twilioLine: twilio });
    return authSend.toPromise();
  }

  automatedDialer(text: string) {
    const test = "Hello, I am so excited to meet with you this afternoon"
    const dialer = this.fireFns.httpsCallable('twilioAutoDialer');
    const dialerSend = dialer({ message: test });
    return dialerSend.toPromise();
  }

  

  voiceDialer(url: string) {
    const test = "https://firebasestorage.googleapis.com/v0/b/welby-dev.appspot.com/o/voice_recordings%2FPhone%20Greeting%20Generic.mp3"
    const dialer = this.fireFns.httpsCallable('twilioVoiceDialer');
    const dialerSend = dialer({ file: test });
    return dialerSend.toPromise();
  }

  // launchVideoChat() {
  //   const id = this.patientService.currentPatientServiceID;
  //   this.router.navigate(['video-chat', id]);
  // }
}
