export interface SecureEmail {
  recipients: string[];
  headers: Headers;
  content: Content;
  forceSecureNotification: boolean;
}
interface Headers {
  subject: string;
  from: string;
}
interface Content {
  'text/plain': string;
}

export enum SecureEmailForm {
  SUBJECT = 'subject',
  RECIPIENTS = 'recipients',
  CC = 'cc',
  FROM = 'from',
  MESSAGE = 'message',
  IS_EMAIL_SECURE = 'isEmailSecure',
  FORCE_SECURE_NOTIFICATION = 'forceSecureNotification',
}
