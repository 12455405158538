import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MeasureValueService {

  constructor() { }

  //return the swiotch statements from the measure type
  returnValueTypes(type: string): {params: string[], units: string} {
    switch(type) {
      case 'Blood Glucose': {
        return {params: ['mgdl'], units: 'mgDl'};
      }
      case 'Blood Pressure': {
        return {params: ['sbp', 'dbp', 'pulse_bpm'], units: 'mmHg'};
      }
      case 'Pulse Ox': {
        return {params: ['spo2'], units: 'Oxygenation'};
      }
      case 'Weight': {
        return {params: ['lbs'], units: 'Pounds'};
      }
      default: {
        return {params: [], units: ''};
      }
   }
  }
}
