import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { distinctUntilChanged, filter, startWith, switchMap } from 'rxjs/operators';
import { PatientListService } from 'src/app/clinical/patient-list/patient-list.service';

@Component({
  selector: 'search-patient-autocomplete',
  templateUrl: './search-patient.component.html',
  styleUrls: ['./search-patient.component.scss'],
})
export class SearchPatientComponent implements OnInit {
  searchInput = new FormControl();
  filteredOptions$: Observable<string[]>;
  searchMax: number = 15;
  constructor(private patientService: PatientListService, private router: Router) {}

  ngOnInit(): void {
    this.filteredOptions$ = this.searchInput.valueChanges.pipe(
      startWith(''),
      filter((text) => !!text && text.length > 2),
      distinctUntilChanged(),
      switchMap((value: string) => this.patientService.getPatients(this.searchMax, { searchTerms: [value.toLowerCase()] }))
    );
  }

  navigatePatientDetail(userId: string): void {
    if (userId) {
      this.router.navigateByUrl(`/clinical/home/${userId}`);
    }
  }

  getOptionText(option) {
    return option ? `${option.firstName} ${option.lastName}` : '';
  }
}
