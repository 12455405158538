import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-careteam-dialog',
  template: `
  <form [formGroup]="teamMemberForm" >
    <h1 mat-dialog-title>{{ data.isNew ? "Add New Team Member" : "Edit Team Member" }}</h1>
    <div mat-dialog-content>
      <mat-form-field style="width:39%; margin-right: 2%;">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="first_name" [(ngModel)]="data.teamMember.first_name" required/>
        <mat-error>First name is reguired</mat-error>
      </mat-form-field>

      <mat-form-field style="width:39%; margin-right: 2%;">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="last_name" [(ngModel)]="data.teamMember.last_name" required/>
        <mat-error>Last name is required</mat-error>
      </mat-form-field>

      <mat-form-field style="width:18%;">
        <mat-label>Degree</mat-label>
        <input matInput formControlName="degree" [(ngModel)]="data.teamMember.degree" />
      </mat-form-field>

      <mat-form-field style="width:100%;">
        <mat-label>Organization</mat-label>
        <input matInput formControlName="organization" [(ngModel)]="data.teamMember.organization"/>
      </mat-form-field>

      <mat-form-field style="width:49%; margin-right: 2%;">
        <mat-label>Phone Number</mat-label>
        <input matInput formControlName="phone" [(ngModel)]="data.teamMember.phone"/>
      </mat-form-field>

      <mat-form-field style="width:49%;">
        <mat-label>Email Address</mat-label>
        <input matInput formControlName="email" [(ngModel)]="data.teamMember.email"/>
      </mat-form-field>

      <mat-form-field style="width:49%; margin-right: 2%;">
        <mat-label>speciality</mat-label>
        <input matInput formControlName="speciality" [(ngModel)]="data.teamMember.speciality" required/>
        <mat-error>Specialty is required</mat-error>
      </mat-form-field>

      <mat-form-field style="width:49%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="type" [(ngModel)]="data.teamMember.type" required>
          <mat-option *ngFor="let type of types" [value]="type">
            {{ type }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <!-- <mat-form-field style="width:47%; margin-right: 5%">
        <mat-label>Select</mat-label>
        <mat-select formControlName="type" [(ngModel)]="data.type" required>
          <mat-option *ngFor="let type of carePlanTypes" [value]="type.value">
            {{ type.viewValue }}
          </mat-option>
        </mat-select>
      </mat-form-field> -->

      <!-- <mat-form-field style="width: 48%">
        <input matInput formControlName="date_opened" [(ngModel)]="data.planItem.date_opened" [matDatepicker]="picker" placeholder="Date Opened" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>Valid date required</mat-error>
      </mat-form-field> -->

    </div>

    <div mat-dialog-actions>
      <button mat-button (click)="onNoClick()">Cancel</button>
      <button mat-raised-button color="primary" [disabled]="teamMemberForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
        Submit
      </button>
    </div>
  </form>
  `,
  styles: []
})
export class CareteamDialogComponent implements OnInit {

  teamMemberForm: FormGroup;
  types = ['primary', 'secondary', 'other']


  constructor(private dialogRef: MatDialogRef<CareteamDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any,
              private fb: FormBuilder) { }

  ngOnInit(): void {
    this.teamMemberForm = this.fb.group ({
      // active: [true, Validators.required],
      first_name: ['', [Validators.required]],
      last_name: ['', [Validators.required]],
      degree: ['', []],
      organization: ['', []],
      // profile_image_url: ['', Validators.required],
      // provider_id: ['', Validators.required],
      speciality: ['', [Validators.required]],
      type: ['', [Validators.required]],
      phone: ['', []],
      email: ['', []],

    })
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
