<mat-progress-bar *ngIf="this.isLoading" color="accent" mode="indeterminate"></mat-progress-bar>
<form [formGroup]="deviceForm">

  <h2 mat-dialog-title>{{ data.isNew ? "Request A New Device" : "Edit Device" }}</h2>
  <div mat-dialog-content>
    <mat-form-field style="width:60%; margin-right: 5%;">
      <mat-label>Order Notes</mat-label>
      <input placeholder="Enter any relevant information about the order request" matInput formControlName="notes"
        [(ngModel)]="data.device.order_notes" required />
      <mat-error>Notes are required to justify the device request</mat-error>
    </mat-form-field>

    <mat-checkbox #deliveryToggle style="width: 35%;">
      Delivery Needed
    </mat-checkbox>

    <mat-form-field style="width:23%; margin-right: 2%;">
      <mat-label>Select</mat-label>
      <mat-select formControlName="manufacturer" (selectionChange)="handleManufacturerSelected()"
        [(ngModel)]="selectedManufacturer" required>
        <mat-option *ngFor="let manufacturer of deviceOrderingService.manufacturerNames$ | async"
          [value]="manufacturer">
          {{ manufacturer }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width:38%; margin-right: 2%">
      <mat-label>Select</mat-label>
      <mat-select formControlName="type" [(ngModel)]="selectedDevice" required>
        <mat-option *ngFor="let device of deviceOrderingService.manufacturerDevices$ | async" [value]="device">
          {{ device.sku_display }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field style="width:35%">
      <mat-label>Device ID</mat-label>
      <input placeholder="Enter Device ID" matInput formControlName="device_id" [(ngModel)]="data.device.device_id" />
      <mat-hint>Leave blank if not ordered</mat-hint>
    </mat-form-field>

    <div id="shipping-block" *ngIf="deliveryToggle.checked">
      <div id="seperater"></div>
      <h5>Shipping Information</h5>
      <mat-form-field style="width:100%;" *ngIf="data.isNew">
        <mat-label>Address</mat-label>
        <input placeholder="Enter the device shipping address" matInput formControlName="shipping_street" />
      </mat-form-field>

      <mat-form-field style="width:33%; margin-right: 2%" *ngIf="data.isNew">
        <mat-label>City</mat-label>
        <input matInput formControlName="shipping_city" [(ngModel)]="data.device.shipping_city" />
      </mat-form-field>

      <mat-form-field style="width: 15%; padding-right: 2%;" *ngIf="data.isNew">
        <mat-label>State</mat-label>
        <mat-select formControlName="shipping_state">
          <mat-option *ngFor="let state of states" [value]="state">
            {{ state }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field style="width:13%; margin-right: 2%" *ngIf="data.isNew">
        <mat-label>Zip</mat-label>
        <input matInput formControlName="shipping_zip" />
      </mat-form-field>

      <mat-form-field style="width:33%;" *ngIf="data.isNew">
        <mat-label>Phone</mat-label>
        <input placeholder="Contact number" matInput formControlName="shipping_phone" />
      </mat-form-field>
      <!-- <button mat-raised-button *ngIf="data.isNew && auth.isAdmin" color="warn"
        (click)="activateDevice( data.device.device_manufacturer, data.device.device_model, 'order')">
        Order
      </button> -->
      <button mat-raised-button *ngIf="data.isNew && roleModel.isAdmin()" color="warn" (click)="orderDevice()">
        Order
      </button>
      <p>{{orderResponse}}</p>
    </div>

    <div style="padding: 20px 0px">
      <mat-slide-toggle style="width:33%" [(ngModel)]="data.device.ordered" [disabled]=true formControlName="ordered">
        Ordered
      </mat-slide-toggle>

      <mat-slide-toggle style="width:33%" [(ngModel)]="data.device.delivered" formControlName="delivered">
        Delivered
      </mat-slide-toggle>

      <mat-slide-toggle style="width:33%" [(ngModel)]="data.device.active" [disabled]=true formControlName="active">
        Active
      </mat-slide-toggle>
    </div>

  </div>



  <div mat-dialog-actions>

    <!-- <button *ngIf="!data.isNew" mat-raised-button color="primary"
      (click)="activateDevice( data.device.device_manufacturer, data.device.device_model, data.device.active? 'deactivate' : 'activate', data.device.device_id)">
      {{data.device.active? "Deactivate" : "Activate"}}
    </button>
    <button mat-raised-button *ngIf="!data.isNew" [disabled]="data.device.active" color="warn"
      (click)="deleteDevice(this.patientService.currentPatientServiceID, data.device.device_id, data.device.device_manufacturer, data.device.device_model)">Delete</button>
    <div fxFlex></div>
    <button mat-button (click)="onNoClick()">Cancel</button>
    <button mat-raised-button color="primary" [disabled]="deviceForm.invalid" [mat-dialog-close]="data" cdkFocusInitial>
      Update
    </button> -->
  </div>
</form>
