<form [formGroup]="addressForm" novalidate (ngSubmit)="onSubmit()" #paymentForm>

  <mat-card-content>
    <div class="row">
      <div class="col">
        <div class="disclaimer">
          Please enter billing information for your monthly subscription charges. You will receive an invoice before any charges are debited. Cancel anytime.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <input matInput placeholder="First name" formControlName="firstName">
          <mat-error *ngIf="addressForm.controls['firstName'].hasError('required')">
            First name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <input matInput placeholder="Last name" formControlName="lastName">
          <mat-error *ngIf="addressForm.controls['lastName'].hasError('required')">
            Last name is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <textarea matInput placeholder="Address" formControlName="address"></textarea>
          <mat-error *ngIf="addressForm.controls['address'].hasError('required')">
            Address is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row" *ngIf="!hasUnitNumber">
      <div class="col">
        <button mat-button type="button" (click)="hasUnitNumber = !hasUnitNumber">
          + Add C/O, Apt, Suite, Unit
        </button>
      </div>
    </div>
    <div class="row" *ngIf="hasUnitNumber">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <textarea matInput placeholder="Address 2" formControlName="address2"></textarea>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <input matInput placeholder="City" formControlName="city">
          <mat-error *ngIf="addressForm.controls['city'].hasError('required')">
            City is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <mat-select placeholder="State" formControlName="state">
            <mat-option *ngFor="let state of states" [value]="state.abbreviation">
              {{ state.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="addressForm.controls['state'].hasError('required')">
            State is <strong>required</strong>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width">
          <input matInput #postalCode maxlength="5" placeholder="Postal Code" type="number"
            formControlName="postalCode">
          <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div #paymentElement class="paymentElement"></div>
      </div>
    </div>
  </mat-card-content>
  
  <mat-card-actions align="end">
    <button mat-button color="primary" type="button" (click)="onCancelClick()" [disabled]="processing">Do this later...</button>
    <button mat-raised-button color="accent" type="submit" *ngIf="!processing">Add Payment Method</button>
    <mat-spinner color="accent" diameter="24" *ngIf="processing"></mat-spinner>
  </mat-card-actions>

</form>