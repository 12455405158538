import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { CreateUserDto } from 'src/app/services/auth/user.factory';
import { DialogService } from 'src/app/services/dialog.service';
import { Md5 } from 'ts-md5';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})


export class HomePageComponent implements OnInit {

  form: FormGroup;
  type: 'login' | 'signup' | 'reset' = 'signup';
  loading = false;
  serverMessage: string;
  toggle = new FormControl(false, []);
  isProvider = false;

  private clearValidators = [
  ];

  private npiValidators = [
    Validators.minLength(9),
    Validators.pattern('[0-9]*')
  ];


  constructor(
    private fb: FormBuilder,
    public afAuth: AngularFireAuth,
    public auth: NewAuthService,
    public dialog: MatDialog,
    public dialogService: DialogService
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      first_name: ['', []],
      last_name: ['', []],
      practice_name: ['', [Validators.minLength(9)]],
      practice_npi: ['', [Validators.minLength(9)]],
      client_id: ['', []],
      password: ['', [Validators.minLength(6), Validators.required]],
      passwordConfirm: ['', []],
      terms: ['', []],
      consent: ['', []],
    });
    this.type = 'login';

    this.toggle.valueChanges.subscribe(() => {
      this.isProvider = this.toggle.value;

      if (this.isProvider && this.type === 'signup') {
        this.form.get('practice_name').setValidators(this.clearValidators.concat(Validators.required));
        this.form.get('practice_npi').setValidators(this.npiValidators.concat(Validators.required));
        this.form.get('first_name').setValidators(this.npiValidators.concat(Validators.required));
        this.form.get('client_id').setValidators(this.clearValidators);
        this.form.get('terms').setValidators(this.clearValidators.concat(Validators.requiredTrue));
        // } else if ( !this.isProvider && this.type === 'signup') {
        //   this.form.get('client_id').setValidators(this.clearValidators.concat(Validators.required));
      } else if (this.type === 'signup') {
        this.form.get('terms').setValidators(this.clearValidators.concat(Validators.requiredTrue));
        this.form.get('consent').setValidators(this.clearValidators.concat(Validators.requiredTrue));
        this.form.get('practice_name').setValidators(this.clearValidators);
        this.form.get('first_name').setValidators(this.npiValidators.concat(Validators.required));
        this.form.get('client_id').setValidators(this.clearValidators.concat(Validators.required));
        this.form.get('practice_npi').setValidators(this.npiValidators);
      } else {
        this.form.get('practice_name').setValidators(this.clearValidators);
        this.form.get('client_id').setValidators(this.clearValidators);
        this.form.get('practice_npi').setValidators(this.npiValidators);
        this.form.get('terms').setValidators(this.npiValidators);
        this.form.get('consent').setValidators(this.npiValidators);
      }

      console.log('a provider?', this.isProvider);
    });
    this.terms.valueChanges.subscribe(() => {
      console.log('terms?', this.terms.value);
    });
    this.consent.valueChanges.subscribe(checkValue => {
      console.log('consent?', this.consent.value);
    });
    console.log('ng on init');
    // this.openNewPatientDialog();
  }

  changeType(val) {
    console.log('valie', val);
    this.type = val;
  }

  get isLogin() {
    return this.type === 'login';
  }

  get isSignup() {
    return this.type === 'signup';
  }

  get isPasswordReset() {
    return this.type === 'reset';
  }

  get email() {
    return this.form.get('email');
  }

  get firstName() {
    return this.form.get('first_name');
  }

  get lastName() {
    return this.form.get('last_name');
  }

  get practiceName() {
    return this.form.get('practice_name');
  }

  get practiceNpi() {
    return this.form.get('practice_npi');
  }

  get patientClientID() {
    return this.form.get('client_id');
  }

  get password() {
    return this.form.get('password');
  }

  get passwordConfirm() {
    return this.form.get('passwordConfirm');
  }

  get terms() {
    return this.form.get('terms').value;
  }

  get consent() {
    if (this.isProvider) {
      return true;
    } else {
      return this.form.get('consent').value;
    }
  }

  get passwordDoesMatch() {
    if (this.type !== 'signup') {
      return true;
    } else {
      return this.password.value === this.passwordConfirm.value;
    }
  }

  async onSubmit() {
    this.loading = true;

    const email = this.email.value;
    const password = this.password.value;
    const firstName = this.firstName.value;
    const lastName = this.lastName.value;
    const practiceName = this.practiceName.value;
    const practiceNPI = this.practiceNpi.value;
    const patientClientID = this.patientClientID.value;

    const createUserDto: CreateUserDto = {
      practice: practiceName,
      fName: firstName,
      lName: lastName,
      npi: practiceNPI,
      clientId: patientClientID,
    }

    try {
      if (this.isLogin) {
        await this.auth.signIn(email, password);
      }
      if (this.isSignup) {
        await this.auth.signUp(email, password, this.isProvider, createUserDto);
      }

      if (this.isPasswordReset) {
        await this.auth.resetPassword();
        this.serverMessage = 'Check your email';
      }
    } catch (err) {
      this.serverMessage = err;
    }
    this.loading = false;
    console.log('logged in');
  }


}

