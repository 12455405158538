import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import * as firebase from 'firebase';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Client } from 'src/app/models/client.model';
import { ClinicalAlert } from 'src/app/models/clinical.model';
import { Patient } from 'src/app/models/patient.model';
import { VitalFlag } from 'src/app/models/vitals/vital-flag.model';

import { NewAuthService } from '../auth/new-auth-service.service';
import { FirestoreService } from '../firestore.service';

@Injectable({
  providedIn: 'root'
})
export class InitialDataModel {

  clinicalAlerts$: Observable<VitalFlag[]>;
  clientPanel$: Observable<Patient[]>;
  clients$: Observable<Client[]>;
  clientProviderPanel$: Observable<Patient[]>;

  clientPanel: Patient[];
  clients = {};
  clientPanelIDArray = [];
  allClientUsers = {};

  constructor(
    private auth: NewAuthService,
    private fsService: FirestoreService,
    private afAuth: AngularFireAuth
  ) { }

  getUsers() {
    this.clientPanel$ = this.fsService.colWithIds$('users', (ref) => ref
      .where('client_responsible_id', 'in', this.auth.user.client_accounts)
      .where('roles.isPatient', '==', true)
      .orderBy('lastName')
    );
    this.clientPanel$.pipe(map((panel: Patient[]) => {
      panel.forEach(patient => {
        if (this.clientPanelIDArray.indexOf(patient.user_id) === -1) {
          this.clientPanelIDArray.push(patient.user_id);
        }
        this.allClientUsers[patient.user_id] = patient;
      });
      return panel
    })).subscribe((panel: Patient[]) => {
      this.clientPanel = panel;
    })
  }

  getClients() {
    if (this.auth.user.roles.isAdmin) {
      this.clients$ = this.fsService.colWithIds$(`clients`);
    } else {
      this.clients$ = this.fsService.colWithIds$(`clients`, (ref) => ref.where('client_id', 'in', this.auth.user.client_accounts));
    }
    this.clients$.subscribe((clients: Client[]) => {
      clients.forEach(c => {
        this.clients[c.id] = c;
      })
    })
  }

  getActiveAlerts() {
    const startDate = new Date().setDate(new Date().getDate() - 90);
    const date = firebase.firestore.Timestamp.fromDate(new Date(startDate))

    if (this.auth.user.roles.isAdmin) { // welby admins can see all alerts
      this.clinicalAlerts$ = this.fsService.colWithIds$('vital_flags', (ref) => ref
        .where('resolved', '==', false)
        .where('invalid', '==', false)
        .where('expired', '==', false)
        .where('alert_date', '>=', date)
        .orderBy('alert_date', 'asc'));
    } else if (this.auth.user.client_accounts.length < 10) {  // a standard practice can limit the call to their practice
      this.clinicalAlerts$ = this.fsService.colWithIds$('vital_flags', (ref) => ref
        .where('resolved', '==', false)
        .where('invalid', '==', false)
        .where('expired', '==', false)
        .where('alert_date', '>=', date)
        .where('client_id', 'in', this.auth.user.client_accounts)
        .orderBy('alert_date', 'asc'));
    } else {  //  odd cases where users have more than 10 accounts they can manage.
      this.clinicalAlerts$ = this.fsService.colWithIds$('vital_flags', (ref) => ref
        .where('resolved', '==', false)
        .where('invalid', '==', false)
        .where('expired', '==', false)
        .where('alert_date', '>=', date)
        .orderBy('alert_date', 'asc'));
    }
  }

  getProviders() {

    this.allClientUsers[this.afAuth.auth.currentUser.uid] = this.auth.user // just always grabbing yourself as a task option

    this.clientProviderPanel$ = this.fsService.colWithIds$('users', (ref) => ref
      .where('client_responsible_id', 'in', this.auth.user.client_accounts).where('roles.isClient', '==', true));

    this.clientProviderPanel$.subscribe((panel: Patient[]) => {
      panel.forEach(provider => {
        this.allClientUsers[provider.user_id] = provider;
      });
    })

  }
}
