export const environment = {
  production: false,

  welbyEndpoint: 'http://localhost:8080',
  welbyUrl: 'http://localhost:4401',

  firebase: {
    apiKey: "AIzaSyD3sfJz0Rza8oqLTem8YF4niSQSPkirU-8",
    authDomain: "welby-demo.firebaseapp.com",
    databaseURL: 'https://welby-demo.firebaseio.com',
    projectId: "welby-demo",
    storageBucket: "welby-demo.appspot.com",
    messagingSenderId: "752725070743",
    appId: "1:752725070743:web:90c7d6ba87581879c3ead2",
    measurementId: "G-QDKE21RN93"
  },

  stripeKey: 'pk_test_YZ8AWD4QSbzy4AZIW9P64hhV00ScSrAald',
  firebaseURL: 'https://us-central1-welby-demo.cloudfunctions.net',
  storageBucket: 'welby-demo.appspot.com',

  pandaApi: '2f1cc803070f974f99ded18b0e3208d4689de3f1',
  welbyBaseUrl: 'http://localhost:4401',

  defaultWelbyEmail: 'company@getwelby.com',
  trial_patient_loads: 5,
};
