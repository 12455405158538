import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OverlayContainer } from '@angular/cdk/overlay';
import { Component, HostBinding, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { NewAuthService } from 'src/app/services/auth/new-auth-service.service';
import { DataService } from 'src/app/services/data.service';
import { RoleModel } from 'src/app/services/models/role-model.service';
import { ThemeService } from 'src/app/services/theme.service';

import * as version from '../../../assets/version.json';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
})
export class ShellComponent implements OnInit {
  versionStr: string = `v ${version.major}.${version.minor}.${version.patch}`;
  isLightMode$: Observable<boolean>;

  themeToggleControl = new FormControl(false);

  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset]).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private breakpointObserver: BreakpointObserver,
    public data: DataService,
    public auth: NewAuthService,
    public roleModel: RoleModel,
    private overlay: OverlayContainer,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.setChangesListenerThemeToggle();
    this.isLightMode$ = this.themeService.isLightModeObservable$;
  }

  setChangesListenerThemeToggle(): void {
    this.themeToggleControl.valueChanges.subscribe((lightMode) => {
      this.themeService.toggleDarkMode();
    });
  }

  logout() {
    this.auth.signOut();
  }
}
