import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InitialDataModel } from 'src/app/services/models/initial-data-model.service';


@Injectable({
    providedIn: 'root'
})
export class InitialDataResolver implements Resolve<any>
{
    constructor(
        private initialData: InitialDataModel
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
        return new Observable((observer) => {
            this.initialData.getUsers();
            this.initialData.getClients();
            this.initialData.getActiveAlerts();
            this.initialData.getProviders();
            setTimeout(() => {
                observer.next();
                observer.complete();
            }, 1000);
        });
    }
}
